import Select from '../ui/Select';
import Button from '../ui/Button';

import ArrowRightIcon from '../../assets/icons/arrow-right.svg?react';
import ArrowLeftIcon from '../../assets/icons/arrow-left.svg?react';
import { useEffect, useState } from 'react';
import { FormItem } from './AssetQualityControlSteps';
import { Option } from '../../common/types';
import { getOptions } from '../../common/utils';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../app/store';
import { setActiveStep } from '../../features/repository/repositoryItemSlice';
function StepValidationForm({
  assetQcReport,
  setAssetQcReport
}: {
  assetQcReport: Record<string, string[] | null | undefined>;
  setAssetQcReport: (assetQcReport: Record<string, string[] | null | undefined>) => void;
}) {
  const dispatch = useDispatch();
  const { steps, activeStep } = useSelector((state: RootState) => state.repositoryItem);

  const [question, setQuestion] = useState<FormItem | undefined>(undefined);

  useEffect(() => {
    if (activeStep) {
      setQuestion(activeStep.form as FormItem);
    }
  }, [activeStep]);

  if (!question) {
    return null;
  }
  if (!activeStep) return null;
  const options = getOptions(question.options);
  const optionsValue = options.filter((option) =>
    assetQcReport[activeStep.id]?.includes(option.value)
  );

  const handleOnChange = (selected: Option[] | Option) => {
    let val;
    if (!Array.isArray(selected)) {
      val = [selected.value];
    } else {
      val = selected.map((option) => option.value);
    }
    setAssetQcReport({ ...assetQcReport, [activeStep.id]: val });
  };

  const activeStepIndex = steps!.findIndex((step) => step.id === activeStep.id);
  const nextStep = steps![activeStepIndex + 1];
  const prevStep = steps![activeStepIndex - 1];

  const canNavigate = (direction: 'next' | 'prev') => {
    if (direction === 'next') {
      return assetQcReport[activeStep.id]?.length && !!nextStep;
    } else {
      return !!prevStep;
    }
  };

  const canNavigateNext = canNavigate('next');
  const canNavigatePrev = canNavigate('prev');

  return (
    <div>
      <div className="text-sm mb-3">
        <p className="text-gray-700 mb-1.5 font-medium">{question.label}</p>
        <Select
          className="text-md mb-2"
          placeholder="Select observations"
          isDisabled={activeStep?.type === 'auto'}
          options={options}
          value={optionsValue}
          onChange={(selected) => handleOnChange(selected as Option[] | Option)}
        />
        {/*<div className="text-gray-600">Multiple observations can be selected</div>*/}
      </div>

      <div className={'flex justify-between mt-3'}>
        <Button
          variant={'defaultOutlined'}
          rounded
          size={'sm'}
          onClick={() => canNavigatePrev && dispatch(setActiveStep(prevStep))}
          disabled={!canNavigatePrev}>
          <ArrowLeftIcon className={'h-4 w-4 stroke-gray-700'} />
          Previous
        </Button>
        <Button
          variant={'primary'}
          rounded
          size={'sm'}
          onClick={() => canNavigateNext && dispatch(setActiveStep(nextStep))}
          disabled={!canNavigateNext}>
          Next <ArrowRightIcon className={'h-4 w-4 stroke-white'} />
        </Button>
      </div>
    </div>
  );
}

export default StepValidationForm;
