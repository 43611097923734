import React from 'react';
import clsx from 'clsx';

import ProgressStepTodo from '../../assets/icons/progress-step-todo.svg?react';
import ProgressStepActive from '../../assets/icons/progress-step-active.svg?react';

import DashIcon from '../../assets/icons/dash.svg?react';
import TickIcon from '../../assets/icons/tick.svg?react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../app/store';
import {
  extractOptionValues,
  QCAutoCheck,
  QCCheck,
  qcChecks
} from '../../features/repository/qualityControlChecks';
import { setActiveStep } from '../../features/repository/repositoryItemSlice';

export interface FormItem {
  label: string;
  options: { [key: string]: string };
  failedOptionKeys: string[];
  warningOptionKeys: string[];
}

interface AssetQualityControlStepsProps {
  className?: string;
  assetQcReport: AssetQcReport;
  steps?: AssetQualityControlStep[];
}
type AssetQcReport = Record<string, string[] | null | undefined>;
export interface AssetQualityControlStep {
  id: string;
  title: string;
  description?: string;
  type: 'manual' | 'auto';
  form: FormItem;
}

/**
 * Renders a vertical list of steps for asset quality control. Each step displays
 * an icon based on whether it is active, completed (pass/fail), or still pending.
 */
const AssetQualityControlSteps: React.FC<AssetQualityControlStepsProps> = ({
  className,
  assetQcReport
}) => {
  const dispatch = useDispatch();
  const { steps, activeStep } = useSelector((state: RootState) => state.repositoryItem);

  /*----- BEGIN Helpers for step click-----*/
  // Only call setSelected if defined and the step is not currently selected
  const handleStepClick = (step: AssetQualityControlStep, isCurrentlySelected: boolean) => {
    if (isCurrentlySelected || !canNavigate(step.id)) return;
    dispatch(setActiveStep(step));
  };

  // Can only navigate to a step if the previous step has been completed
  const canNavigate = (stepId: string) => {
    if (!steps) return false;
    const stepIndex = steps?.findIndex((step) => step.id === stepId);
    if (stepIndex === 0) {
      return true;
    }
    const previousStep = steps[stepIndex - 1];
    const previousStepResponse = assetQcReport[previousStep.id] ?? [];
    return previousStepResponse.length;
  };
  /*----- END Helpers for step click-----*/

  let lastIndexWithResponse = -1;

  return (
    <div className={clsx('flex flex-col gap-1.5 max-h-max', className)}>
      {steps?.map((step, index) => {
        lastIndexWithResponse = assetQcReport[step.id]?.length ? index : lastIndexWithResponse;
        const isStepSelected = activeStep?.id === step.id;
        return (
          <div
            key={step.id}
            className={clsx('relative flex gap-3', {
              'cursor-pointer': canNavigate(step.id),
              'cursor-not-allowed': !canNavigate(step.id)
            })}
            onClick={() => handleStepClick(step, isStepSelected)}>
            <div className="flex flex-col">
              {/* Icon */}
              <div className="h-6 w-6">
                <StepIcon
                  step={step}
                  stepAnswer={assetQcReport[step.id]}
                  isActive={isStepSelected}
                />
              </div>

              {/* Connector Line (except for the last item) */}
              {index < steps.length - 1 && (
                <div className="flex flex-grow items-center justify-center relative h-full mt-1.5 -top-px">
                  <span className="bg-primary-500 w-0.5 h-full" />
                </div>
              )}
            </div>

            {/* Step Title & Description */}
            <div>
              <div className="text-sm font-semibold text-gray-700 mb-4 min-h-8">{step.title}</div>
              {/*<div className="text-sm text-gray-600 mb-4">{step.description}</div>*/}
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default AssetQualityControlSteps;

///------ COMPONENTS ------
/**
 * Determines which icon to display for each step based on whether it's active,
 * and the data in the qcReport.
 */
const Border = () => (
  <div
    className={
      'absolute w-[calc(100%+10px)] h-[calc(100%+10px)] ' +
      'border-primary-500 border-2 rounded-full ' +
      'left-1/2 -translate-x-1/2 top-1/2 -translate-y-1/2'
    }
  />
);

const ProgressStepAuto = ({ className }: { className: string }) => {
  return (
    <div
      className={clsx(
        'w-6 h-6 rounded-full text-sm font-medium text-white grid place-items-center',
        className
      )}>
      A
    </div>
  );
};

const ProgressStepManual = ({ className, pass }: { className: string; pass: boolean }) => {
  return (
    <div
      className={clsx(
        'w-6 h-6 rounded-full text-sm font-medium text-white grid place-items-center',
        className
      )}>
      {pass ? (
        <TickIcon className="w-3 h-3 fill-white" />
      ) : (
        <DashIcon className="w-3 h-3 fill-white" />
      )}
    </div>
  );
};
const stepColors = {
  success: 'bg-primary-600',
  warning: 'bg-warning',
  danger: 'bg-danger'
};
function StepIcon({
  step,
  isActive,
  stepAnswer
}: {
  step: AssetQualityControlStep;
  isActive: boolean;
  stepAnswer: string[] | null | undefined;
}) {
  // Show the "active" icon if this is the currently selected step

  const qcResponse = stepAnswer ?? [];

  if (isActive) {
    if (!qcResponse.length) {
      return (
        <div className="relative h-6 w-6">
          <ProgressStepActive className="h-full w-full" />
          <Border />
        </div>
      );
    } else {
      return (
        <div className="relative h-6 w-6">
          <StepIcon step={step} isActive={false} stepAnswer={stepAnswer} />
          <Border />
        </div>
      );
    }
  }

  // If there's no QC response, show the TODO icon
  if (qcResponse.length === 0) {
    return <ProgressStepTodo className="w-6 h-6" />;
  }

  // Determine if it should show failing icon
  const hasFailingOption = qcResponse.some((response) =>
    step.form.failedOptionKeys.includes(response)
  );

  if (hasFailingOption) {
    return step.type === 'auto' ? (
      <ProgressStepAuto className={stepColors.danger} />
    ) : (
      <ProgressStepManual className={stepColors.danger} pass={false} />
    );
  }

  // Determine if it should show warning icon
  const hasWarningOption = qcResponse.some((response) =>
    step.form.warningOptionKeys.includes(response)
  );

  if (hasWarningOption) {
    return step.type === 'auto' ? (
      <ProgressStepAuto className={stepColors.warning} />
    ) : (
      <ProgressStepManual className={stepColors.warning} pass={false} />
    );
  }

  // If there's no failing or warning option, show the passing

  return step.type === 'auto' ? (
    <ProgressStepAuto className={stepColors.success} />
  ) : (
    <ProgressStepManual className={stepColors.success} pass={true} />
  );
}
///------ HELPERS ------

export function getChecksForAssetType(type: string | undefined): {
  auto: QCAutoCheck[];
  manual: QCCheck[];
} {
  switch (type) {
    case 'VIDEO':
      return qcChecks.video;
    case 'AUDIO':
      return qcChecks.audio;
    case 'SUBTITLE':
      return qcChecks.subtitle;
    case 'CLOSED_CAPTION':
      return qcChecks.closedcaption;
    default:
      console.error('Unknown asset type:', type);
      return { auto: [], manual: [] };
  }
}

export const extractStepsFromQcChecks = ({
  auto,
  manual
}: {
  auto: QCAutoCheck[];
  manual: QCCheck[];
}) => [...extractAutoChecks(auto), ...extractManual(manual)];

function extractOptions(
  options: { value: string; answer: string }[] | undefined
): { [key: string]: string } | undefined {
  const result: Record<string, string> = {};

  options?.forEach((option) => {
    result[option.value] = option.answer;
  });

  return result;
}

function extractManual(manual: QCCheck[]) {
  return manual.map(
    (step) =>
      ({
        id: step.id,
        title: step.question,
        type: 'manual',
        form: {
          label: step.question,
          options: extractOptions(step.options),
          failedOptionKeys: extractOptionValues(step, 'FAILED'),
          warningOptionKeys: extractOptionValues(step, 'WARNING')
        }
      }) as AssetQualityControlStep
  );
}

function extractAutoChecks(auto: QCAutoCheck[]): AssetQualityControlStep[] {
  return auto.map((step) => {
    return {
      id: step.id,
      title: step.question,
      type: 'auto',
      form: {
        label: step.question,
        options: extractOptions(step.options),
        failedOptionKeys: extractOptionValues(step, 'FAILED'),
        warningOptionKeys: extractOptionValues(step, 'WARNING')
      }
    } as AssetQualityControlStep;
  });
}
