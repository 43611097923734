import { EditVersion, HistoryLog, Meta, Studio } from '../../common/types';

export interface RepositoryItem {
  catalogIdType: string;
  title: string;
  itemType: string;
  repository: string;
  eidrId: string;
  titleCatalogId: string;
  editVersion: string;
  editEidrId: string;
  folderLocation: string;
  parentId: string;
  seasonNumber: string;
  episodeNumber: string;
  releaseDate: string;
  createdAt: string;
  modifiedAt: string;
  history: {
    user: string;
    userFullName: string;
    userOrganization: string;
    userOrganizationName: string;
    action: string;
    message: string;
    timestamp: string;
  }[];
  _id: string;
}
export interface RepositoryItemListItem {
  _id: string;
  itemType: RepositoryItemType;
  title: string;
  eidrId: string;
  catalogId?: string;
  catalogIdType?: string;
  repository: Repository;
  editVersion: EditVersion;

  seriesId?: string;
  seriesTitle?: string;
  seriesEidrId?: string;
  seriesCatalogId?: string;
  seriesCatalogIdType?: string;
  seriesSeasonNumbers?: number[];

  seasonNumber?: number;
  episodeNumber?: number;
  releaseDate: string;
  modifiedAt: string;
  createdAt: string;
  qcStatus: RepositoryItemQcStatus[];
}

export const RepositoryItemTypeLabels: Record<string, string> = {
  MOVIE: 'Movie',
  SERIES: 'Series',
  EPISODE: 'Episode',
  SHORT: 'Short',
  STANDALONE: 'Standalone'
} as const;

export type RepositoryItemType = keyof typeof RepositoryItemTypeLabels;

export const RepositoryLabels: Record<string, string> = {
  HBO__TV: 'TV',
  HBO__MOVIES: 'Movies',
  HBO__SHORTS: 'Shorts',
  HBO__STANDALONE: 'Standalone',

  WARNER_BROS__TV: 'TV',
  WARNER_BROS__MOVIES: 'Movies',
  WARNER_BROS__SHORTS: 'Shorts',
  WARNER_BROS__STANDALONE: 'Standalone',

  DISNEY__TV: 'TV',
  DISNEY__MOVIES: 'Movies',
  DISNEY__SHORTS: 'Shorts',
  DISNEY__STANDALONE: 'Standalone',

  TURNER__TV: 'TV',
  TURNER__MOVIES: 'Movies',
  TURNER__SHORTS: 'Shorts',
  TURNER__STANDALONE: 'Standalone',

  SONY__TV: 'TV',
  SONY__MOVIES: 'Movies',
  SONY__SHORTS: 'Shorts',
  SONY__STANDALONE: 'Standalone',

  ADULT_SWIM__TV: 'TV',
  ADULT_SWIM__MOVIES: 'Movies',
  ADULT_SWIM__SHORTS: 'Shorts',
  ADULT_SWIM__STANDALONE: 'Standalone',

  UNIVERSAL__TV: 'TV',
  UNIVERSAL__MOVIES: 'Movies',
  UNIVERSAL__SHORTS: 'Shorts',
  UNIVERSAL__STANDALONE: 'Standalone',

  CARTOON_NETWORK__TV: 'TV',
  CARTOON_NETWORK__MOVIES: 'Movies',
  CARTOON_NETWORK__SHORTS: 'Shorts',
  CARTOON_NETWORK__STANDALONE: 'Standalone'
} as const;

export type Repository = keyof typeof RepositoryLabels;

export const CatalogIdTypeDistributorMapping = {
  MPM: ['WARNER_BROS', 'HBO', 'SONY', 'TURNER', 'UNIVERSAL', 'ADULT_SWIM', 'CARTOON_NETWORK']
};

export const RepositoryItemQcStatusLabels: Record<string, string> = {
  TO_QC: 'to QC',
  TO_CONFORM: 'to Conform'
} as const;

export type RepositoryItemQcStatus = keyof typeof RepositoryItemQcStatusLabels;

export const DistributorRepositoryMapping: Record<Studio, Repository[]> = Object.fromEntries(
  [
    'WARNER_BROS',
    'HBO',
    'DISNEY',
    'SONY',
    'TURNER',
    'UNIVERSAL',
    'ADULT_SWIM',
    'CARTOON_NETWORK'
  ].map((studio) => [
    studio,
    Object.keys(RepositoryLabels).filter((key) => key.startsWith(`${studio}__`)) as Repository[]
  ])
);

export interface RepositoryItemDetails {
  _id: string;
  title: string;
  itemType: string;
  repository: Repository;
  titleCatalogId: string;
  catalogIdType: string;
  eidrId: string;
  editVersion: EditVersion;
  editEidrId: string;
  folderLocation: string;
  parentId: string;
  seasonNumber?: number;
  episodeNumber?: number;
  releaseDate: string;
  createdAt: string;
  modifiedAt: string;
  assets: Asset[];
  history: HistoryLog[];
}

export interface Asset {
  _id: string;
  location: string;
  type: AssetType;
  status: AssetStatus;
  language: string;
  tracks: [AssetTrack];
  mediainfo: string[];
  editId: number;
  size: number;
  comment: string;
  statusModifiedAt: string;
  modifiedAt: string;
  createdAt?: string;
  createdBy?: string;
  history: HistoryLog[];
}

export interface AssetTrack {
  index: number;
  track_type: AssetTrackType;
  language: string;
  enabled: boolean;
}

export const AssetStatusLabels: Record<string, string> = {
  NEW: 'New',
  ACCEPTED: 'Accepted',
  TO_CONFORM: 'To Conform',
  TO_DELETE: 'To Delete',
  DELETED: 'Deleted',
  MISSING: 'Missing'
} as const;

export type AssetStatus = keyof typeof AssetStatusLabels;

export const AssetTypeLabels: Record<string, string> = {
  VIDEO: 'Video',
  AUDIO: 'Audio',
  SUBTITLE: 'Subtitle',
  CLOSED_CAPTION: 'Closed Caption',
  UNKNOWN: 'Unknown'
} as const;

export type AssetType = keyof typeof AssetTypeLabels;

export const AssetTrackTypeLabels: Record<string, string> = {
  video: 'Video',
  audio: 'Audio',
  subtitle: 'Subtitle',
  closedcaption: 'Closed Caption',
  data: 'Data'
} as const;

export type AssetTrackType = keyof typeof AssetTrackTypeLabels;

// ---------- BEGIN QC Report types
export const QCReportResolutionLabels: Record<string, string> = {
  ACCEPTED: 'Accept',
  TO_DELETE: 'Delete',
  TO_CONFORM: 'Conform'
};

export type QCReportResolution = keyof typeof QCReportResolutionLabels;

export const QCReportQuestionVerdictLabels: Record<string, string> = {
  PASSED: 'Passed',
  FAILED: 'Failed',
  WARNING: 'Warning'
};

export type QCReportQuestionVerdict = keyof typeof QCReportQuestionVerdictLabels;

export interface AssetQCReportInput {
  questions: QCReportQuestionInput[];
  generalObservation: string;
  resolution: QCReportResolution;
}

export interface QCReportQuestionInput {
  question: string;
  answer: string;
  verdict: QCReportQuestionVerdict;
}

export interface AddAssetQCReportOutput {
  added: boolean;
  reportId: string;
  existingAcceptedAsset: Asset;
}
export interface AddAssetQCReportOutputResponse {
  addAssetQCReport: AddAssetQCReportOutput;
}
// ---------- END QC Report types

// API

export interface ListRepositoryItemsRequest {
  distributor?: string;
  repository?: string;
  parentId?: string;
  seasonNumber?: number;
  qcStatus?: string;
  search?: string;
  page?: string;
  pageSize?: string;
}
export interface ListRepositoryItemsResult {
  listRepositoryItems: { results: ListRepositoryItemsResultsItem[]; meta: Meta | null };
}
export interface ListRepositoryItemsResultsItem {
  _id: string;
  title: string;
  itemType: RepositoryItemType;
  repository: Repository;
  eidrId: string;
  titleCatalogId: string;
  catalogIdType: string;
  editVersion: EditVersion;
  editEidrId: string;
  folderLocation: string;
  parentId: string;
  seasonNumber?: number;
  seasonNumbers?: number[];
  episodeNumber?: number;
  releaseDate: string;
  createdAt: string;
  modifiedAt: string;
  hasAssetToQc: boolean;
  hasAssetToConform: boolean;
  series?: ListRepositoryItemsResultsItemSeries;
}
export interface ListRepositoryItemsResultsItemSeries {
  _id: string;
  title: string;
  itemType: RepositoryItemType;
  repository: Repository;
  eidrId: string;
  titleCatalogId: string;
  catalogIdType: string;
}

export interface UpdateRepositoryItemExternalIdsInput {
  catalogId?: string;
  catalogIdType?: string;
  eidrId?: string;
}

export interface UpdateRepositoryItemCatalogIdAndEidrOutput {
  updatedOne: boolean;
  updatedAll: boolean;
  itemsWithSameCatalogId: RepositoryItem[];
  itemsWithSameEidr: RepositoryItem[];
}

export interface GetRepositoryItemAndAssetsResponse {
  getRepositoryItemById: RepositoryItemDetails;
  listAssets: Asset[];
}

export interface GetRepositoryItemAssetsResponse {
  listAssets: Asset[];
}

export interface CastlabsProcessStatus {
  action: string;
  data: string;
  end_date: string;
  id: string;
  message: string;
  start_date: string;
  state: string;
}

//---------------------------Asset Media Info Types ----------------------------------------

//------- VIDEO-------
interface MediaInfoVideoGeneralInfo {
  IsStreamable: string;
  '@type': string;
  Encoded_Library_Name: string;
  CodecID_Compatible: string;
  CodecID: string;
  Format: string;
  OtherCount: string;
  FileExtension: string;
  DataSize: string;
  Encoded_Library: string;
  extra: {
    TIM: string;
    TSZ: string;
    FileExtension_Invalid: string;
    TSC: string;
  };
  StreamSize: string;
  FooterSize: string;
  VideoCount: string;
  FrameRate: string;
  CodecID_Version: string;
  AudioCount: string;
  OverallBitRate: string;
  FrameCount: string;
  Duration: string;
  OverallBitRate_Mode: string;
  Tagged_Date: string;
  Format_Profile: string;
  Encoded_Date: string;
  HeaderSize: string;
  FileSize: string;
}
interface MediaInfoVideoVideoInfo {
  FrameRate_Mode: string;
  '@type': string;
  StreamOrder: string;
  ColorSpace: string;
  Sampled_Width: string;
  colour_primaries: string;
  CodecID: string;
  Delay: string;
  transfer_characteristics: string;
  Format: string;
  Format_Version: string;
  Language: string;
  Encoded_Library: string;
  Height: string;
  colour_primaries_Source: string;
  ID: string;
  StreamSize: string;
  Width: string;
  FrameRate: string;
  colour_description_present: string;
  colour_description_present_Source: string;
  FrameCount: string;
  matrix_coefficients: string;
  transfer_characteristics_Source: string;
  Duration: string;
  ChromaSubsampling: string;
  Rotation: string;
  DisplayAspectRatio: string;
  Sampled_Height: string;
  ScanType: string;
  Tagged_Date: string;
  matrix_coefficients_Source: string;
  Format_Profile: string;
  BitRate: string;
  Encoded_Date: string;
  PixelAspectRatio: string;
  BitRate_Mode: string;
}
interface MediaInfoVideoAudioInfo {
  ChannelPositions: string;
  '@type': string;
  StreamOrder: string;
  Format_Settings_Sign: string;
  ChannelLayout: string;
  Format_Settings_Endianness: string;
  Duration: string;
  Delay_DropFrame: string;
  CodecID: string;
  SamplingRate: string;
  BitDepth: string;
  Channels: string;
  Delay: string;
  Delay_Source: string;
  Format: string;
  SamplingCount: string;
  Language: string;
  Tagged_Date: string;
  ID: string;
  StreamSize: string;
  StreamSize_Proportion: string;
  BitRate: string;
  Encoded_Date: string;
  BitRate_Mode: string;
}
interface MediaInfoVideoTimeCodeInfo {
  TimeCode_Striped: string;
  TimeCode_FirstFrame: string;
  Type: string;
  FrameRate: string;
  Format: string;
  Language: string;
  '@type': string;
  StreamOrder: string;
  extra: {
    Encoded_Date: string;
    Tagged_Date: string;
  };
  Duration: string;
  ID: string;
}

export type MediaInfoVideo = [
  MediaInfoVideoGeneralInfo,
  MediaInfoVideoVideoInfo,
  MediaInfoVideoAudioInfo,
  MediaInfoVideoTimeCodeInfo
];

//------- AUDIO-------
interface MediaInfoAudioGeneralInfo {
  Format: string;
  OverallBitRate_Mode: string;
  '@type': string;
  AudioCount: string;
  FileExtension: string;
  OverallBitRate: string;
  extra: {
    IsTruncated: string;
    FileExtension_Invalid: string;
  };
  Duration: string;
  StreamSize: string;
  FileSize: string;
}
interface MediaInfoAudioDetailedInfo {
  '@type': string;
  Format_Settings_Sign: string;
  Format_Settings_Endianness: string;
  Duration: string;
  CodecID: string;
  SamplingRate: string;
  BitDepth: string;
  Channels: string;
  Format: string;
  SamplingCount: string;
  StreamSize: string;
  StreamSize_Proportion: string;
  BitRate: string;
  BitRate_Mode: string;
}
export type MediaInfoAudio = [MediaInfoAudioGeneralInfo, MediaInfoAudioDetailedInfo];

//------- SUBTITLE-------
interface MediaInfoSubtitleGeneralInfo {
  Format: string;
  '@type': string;
  FileExtension: string;
  extra: {
    FileExtension_Invalid: string;
  };
  StreamSize: string;
  TextCount: string;
  FileSize: string;
}
interface MediaInfoSubtitleDetailedInfo {
  '@type': string;
  Format: string;
  Compression_Mode: string;
}
export type MediaInfoSubtitle = [MediaInfoSubtitleGeneralInfo, MediaInfoSubtitleDetailedInfo];
