import Modal from '../ui/Modal';
import CloseIcon from '../../assets/icons/close.svg?react';
import Textarea from '../ui/Textarea';
import Button from '../ui/Button';
import CrossIcon from '../../assets/icons/close.svg?react';
import ShareIcon from '../../assets/icons/share-2.svg?react';
import { useState } from 'react';

function RejectAssetModal({
  comment,
  onSubmit
}: {
  comment: string | undefined;
  onSubmit: (action: 'CANCEL' | 'TO_CONFORM' | 'TO_DELETE', comment?: string) => void;
}) {
  const [text, setText] = useState(comment);

  const clickHandler = (action: 'CANCEL' | 'TO_CONFORM' | 'TO_DELETE') => {
    onSubmit(action, text);
  };

  return (
    <Modal
      visible={true}
      setVisible={() => null}
      showHeader={false}
      className={'min-w-max max-w-max'}>
      <div className="p-4">
        {/* Header */}
        <div className={'flex w-full justify-between mb-5'}>
          <div>
            <h3 className="text-gray-900 text-lg font-semibold mb-1">Send to conform or reject?</h3>
            <p className="text-gray-600 text-sm">
              Can this file be conformed or should we re-order it?
            </p>
          </div>
          <div className="flex-none cursor-pointer" onClick={() => clickHandler('CANCEL')}>
            <CloseIcon className="h-6 w-6 stroke-gray-400" />
            <span className="sr-only">Close</span>
          </div>
        </div>
        {/* Content */}
        <div>
          <div>
            <h4 className="text-gray-700 text-sm font-medium mb-1.5">
              Final assessment for this file
            </h4>
            <Textarea
              rounded
              value={text}
              onChange={(e) => setText(e.target.value)}
              className="min-h-32 max-h-56 text-md"
              placeholder="Additional message to document this action..."
            />
          </div>
        </div>
        {/* Footer */}
        <div className="flex justify-end mt-5 gap-2">
          <Button rounded onClick={() => clickHandler('CANCEL')}>
            Cancel
          </Button>
          <Button
            rounded
            variant="primary"
            className="font-semibold text-md min-w-max"
            onClick={() => clickHandler('TO_CONFORM')}>
            <ShareIcon className="h-5 w-5 stroke-white  " />
            To conform
          </Button>
          <Button
            rounded
            variant="danger"
            className="font-semibold text-md"
            onClick={() => clickHandler('TO_DELETE')}>
            <div className="rounded-full border-2 border-white max-w-min  p-0.5">
              <CrossIcon className="h-3 w-3 stroke-white [&>*]:stroke-[4px] " />
            </div>
            Delete/Reorder*
          </Button>
        </div>
        <div className={'text-gray-600 text-sm mt-4'}>
          *Reordering would have to be done manually.
        </div>
      </div>
    </Modal>
  );
}

export default RejectAssetModal;
