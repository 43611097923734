import Textarea from '../ui/Textarea';
import Button from '../ui/Button';
import React from 'react';
import CrossIcon from '../../assets/icons/close.svg?react';
import CheckIcon from '../../assets/icons/check.svg?react';
import HelpIcon from '../../assets/icons/help.svg?react';
import { Tooltip } from '../ui/Tooltip';
import RejectAssetModal from './RejectAssetModal';

interface AssessmentFormProps {
  comment: string | undefined;
  setComment: (comment?: string) => void;
  canApprove: boolean;
  onSubmit: (action: ComponentAction) => void;
}

type ComponentAction = 'ACCEPTED' | 'TO_CONFORM' | 'TO_DELETE';

function AssessmentForm({ comment, setComment, canApprove, onSubmit }: AssessmentFormProps) {
  const [isRejectModalVisible, setRejectModalVisible] = React.useState<boolean>(false);

  const onFormSubmit = (action: ComponentAction) => {
    onSubmit(action);
  };

  const onApprove = () => onFormSubmit('ACCEPTED');
  const onReject = () => setRejectModalVisible(true);

  const handleRejectModalSubmit = (
    action: 'TO_CONFORM' | 'TO_DELETE' | 'CANCEL',
    comment?: string
  ) => {
    if (action !== 'CANCEL') {
      setComment(comment);
      onSubmit(action);
    }
    setRejectModalVisible(false);
  };

  return (
    <>
      <div className={'mt-8 text-sm flex flex-col gap-1.5'}>
        <div className={'text-gray-700 font-medium items-center flex'}>
          Final assessment for this file
          <Tooltip
            content={
              <div className={'max-w-64'}>
                In this asset specific field you can record observations and comments that supports
                your final assessment.
              </div>
            }>
            <HelpIcon className={'h-4 w-4 ml-1'} />
          </Tooltip>
        </div>
        <Textarea
          className={'text-md min-h-32 max-h-64'}
          rounded
          placeholder={'General observations...'}
          value={comment}
          onChange={(e) => setComment(e.target.value)}
        />
        <div className={'text-gray-600'}>This field is optional</div>
      </div>
      <div className={'mb-8 mt-3 flex justify-end gap-8 '}>
        <Button
          disabled={false}
          rounded
          size={'sm'}
          variant="danger"
          className="flex gap-1 items-center "
          onClick={onReject}>
          <CrossIcon className="stroke-white h-5 w-5" /> Reject
        </Button>

        <Button
          disabled={!canApprove}
          rounded
          size={'sm'}
          className="flex flex-row gap-1 items-center"
          onClick={onApprove}>
          <CheckIcon className="stroke-gray-800 h-5 w-5" /> Approve
        </Button>
      </div>
      {isRejectModalVisible && (
        <RejectAssetModal comment={comment} onSubmit={handleRejectModalSubmit} />
      )}
    </>
  );
}

export default AssessmentForm;
