import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useForm } from 'react-hook-form';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { RootState } from '../../app/store';
import { updateRepositoryItemExternalIds } from '../../features/repository/repositoryItemSlice';
import {
  RepositoryItem,
  UpdateRepositoryItemExternalIdsInput
} from '../../features/repository/types';
import Input from '../ui/Input';
import Button from '../ui/Button';
import CheckDoneIcon from '../../assets/icons/check-done.svg?react';
import SaveIcon from '../../assets/icons/save.svg?react';
import ExternalLinkIcon from '../../assets/icons/external-link.svg?react';
import toast from 'react-hot-toast';
import { updateRepositoryItemCatalogIdAndEidr } from '../../features/repository/repositoryApi';
import ConfirmUpdateRepositoryItemExternalIdsModal from './ConfirmUpdateRepositoryItemExternalIdsModal';
export default function EditRepositoryItemExternalIdsForm() {
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();

  const { repositoryItem } = useSelector((state: RootState) => state.repositoryItem);
  if (!repositoryItem) {
    return null;
  }
  const { register, handleSubmit, setValue, getValues, watch } =
    useForm<UpdateRepositoryItemExternalIdsInput>({
      defaultValues: {
        catalogId: '',
        catalogIdType: 'MPM',
        eidrId: ''
      }
    });

  const formWatcher = watch(['catalogId', 'eidrId']);

  const [itemsWithSameIds, setItemsWithSameIds] = useState<
    | {
        itemsWithSameCatalogId?: RepositoryItem[];
        itemsWithSameEidr?: RepositoryItem[];
      }
    | undefined
  >(undefined);

  useEffect(() => {
    if (repositoryItem) {
      setValue('catalogId', repositoryItem.titleCatalogId);
      setValue('eidrId', repositoryItem.eidrId);
    }
  }, [repositoryItem, setValue]);
  const isFormChanged = !(
    formWatcher[0] === repositoryItem.titleCatalogId && formWatcher[1] === repositoryItem.eidrId
  );

  const [isSubmitting, setIsSubmitting] = useState(false);
  const onFormSubmit = handleSubmit(async (data) => {
    if (!repositoryItem || isSubmitting) return;
    setIsSubmitting(true);
    const tryUpdate = await updateRepositoryItemCatalogIdAndEidr(repositoryItem?._id, {
      catalogId: data.catalogId,
      newCatalogIdType: data.catalogIdType,
      eidrId: data.eidrId,
      updateAll: false,
      updateOne: false
    });

    if (tryUpdate.itemsWithSameCatalogId?.length > 0 || tryUpdate.itemsWithSameEidr?.length > 0) {
      setItemsWithSameIds({
        itemsWithSameCatalogId: tryUpdate.itemsWithSameCatalogId,
        itemsWithSameEidr: tryUpdate.itemsWithSameEidr
      });
      setIsSubmitting(false);
      return;
    }

    if (tryUpdate.updatedOne) {
      dispatch(updateRepositoryItemExternalIds(data));
      toast.success('Title has been updated');
      setIsSubmitting(false);
      return;
    }

    const singleUpdate = await updateRepositoryItemCatalogIdAndEidr(repositoryItem?._id, {
      catalogId: data.catalogId,
      newCatalogIdType: data.catalogIdType,
      eidrId: data.eidrId,
      updateAll: false,
      updateOne: true
    });
    if (singleUpdate.updatedOne) {
      dispatch(updateRepositoryItemExternalIds(data));
      toast.success('Title has been updated');
    }
    setIsSubmitting(false);
  });

  async function saveExternalIds(param: { updateOne: boolean; updateAll: boolean }) {
    if (!repositoryItem) return;

    const data = getValues();

    const tryUpdate = await updateRepositoryItemCatalogIdAndEidr(repositoryItem?._id, {
      catalogId: data.catalogId,
      newCatalogIdType: data.catalogIdType,
      eidrId: data.eidrId,
      updateAll: param.updateAll,
      updateOne: param.updateOne
    });

    return tryUpdate.updatedOne || tryUpdate.updatedAll;
  }

  async function handleConflictSubmit(action: 'CANCEL' | 'UPDATE_ONE' | 'UPDATE_ALL') {
    switch (action) {
      case 'CANCEL':
        setItemsWithSameIds(undefined);
        break;
      case 'UPDATE_ONE': {
        const resUpdateOne = await saveExternalIds({ updateOne: true, updateAll: false });
        if (resUpdateOne) {
          dispatch(updateRepositoryItemExternalIds(getValues()));
          setItemsWithSameIds(undefined);
          toast.success('Title has been updated');
        } else {
          toast.error('Failed to update title');
        }
        break;
      }
      case 'UPDATE_ALL': {
        const resUpdateAll = await saveExternalIds({ updateOne: false, updateAll: true });
        if (resUpdateAll) {
          dispatch(updateRepositoryItemExternalIds(getValues()));
          setItemsWithSameIds(undefined);
          toast.success('All titles with the same ID have been updated');
        } else {
          toast.error('Failed to update all titles with the same ID');
        }
        break;
      }
    }
  }

  return (
    <>
      <form className="flex flex-col gap-5" onSubmit={onFormSubmit}>
        {repositoryItem?.catalogIdType && (
          <Section
            label={`${repositoryItem.catalogIdType} ID`}
            placeholder="Enter catalog id here"
            valueKey="catalogId"
            href={getWbtvdLink(repositoryItem.titleCatalogId)}
            register={register}
          />
        )}

        <Section
          label="EIDR ID"
          placeholder="Enter EIDR ID here"
          valueKey="eidrId"
          href={getEidrLink(repositoryItem.eidrId)}
          register={register}
        />

        <div className="flex justify-between mt-4">
          <Button
            variant="defaultOutlined"
            rounded
            type={'button'}
            onClick={() => navigate(`${location.pathname}/quality-control`)}
            aria-label="Quality Check">
            <CheckDoneIcon className="stroke-gray-600 h-5 w-5" />
            <span className="font-semibold text-gray-700">QC this asset</span>
          </Button>
          <Button
            variant="primary"
            rounded
            aria-label="Save Changes"
            disabled={!isFormChanged || isSubmitting}>
            <SaveIcon className="stroke-white h-5 w-5" />
            <span className="font-semibold">Save</span>
          </Button>
        </div>
      </form>
      <ConfirmUpdateRepositoryItemExternalIdsModal
        itemsWithSameIds={itemsWithSameIds}
        formValues={getValues()}
        onSubmit={handleConflictSubmit}
      />
    </>
  );
}

interface SectionProps {
  label: string;
  placeholder: string;
  valueKey: keyof UpdateRepositoryItemExternalIdsInput;
  href: string;
  register: ReturnType<typeof useForm>['register'];
}

const Section: React.FC<SectionProps> = ({ label, placeholder, valueKey, href, register }) => (
  <>
    <div className="flex items-center">
      <div className="basis-1/4">
        <label className="text-gray-700 font-semibold">{label}</label>
      </div>
      <div className="basis-3/4 flex gap-8 items-center">
        <Input rounded placeholder={placeholder} {...register(valueKey)} />
        <LinkOrPlaceholder href={href} />
      </div>
    </div>
    <div className="w-full bg-gray-100 h-px my-2" />
  </>
);

const getWbtvdLink = (catalogId?: string) =>
  catalogId?.trim() ? `https://www.wbtvd.com/search?q=${catalogId}` : '';

const getEidrLink = (eidrId?: string) =>
  eidrId?.trim() ? `https://ui.eidr.org/view/content?id=${eidrId}` : '';

interface LinkOrPlaceholderProps {
  href: string;
}

const LinkOrPlaceholder: React.FC<LinkOrPlaceholderProps> = ({ href }) => {
  if (!href) {
    return <ExternalLinkIcon className="stroke-gray-300 h-5 w-5 cursor-not-allowed" />;
  }

  return (
    <Link to={href} target="_blank" aria-label="External Link">
      <ExternalLinkIcon className="stroke-gray-600 h-5 w-5" />
    </Link>
  );
};
