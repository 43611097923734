import ArrowRightIcon from '../../assets/icons/arrow-right.svg?react';
import Button from '../../components/ui/Button';
import { Title as OrdersTitle } from '../../features/orders/types';
import { Title as CatalogTitle } from '../../features/catalog/types';
import { RepositoryItemListItem } from '../../features/repository/types';
import { Popover, PopoverAnchor, PopoverContent } from '../ui/Popover';
import { useState } from 'react';
import ExternalIcon from '../../assets/icons/external-link.svg?react';
import clsx from 'clsx';

interface Title {
  name: string;
  isEpisode: boolean;
  seriesName?: string;
  seasonNumber?: string;
  episodeNumber?: string;
}

export const TitleLabel = (props: {
  catalogTitle?: CatalogTitle;
  orderTitle?: OrdersTitle;
  repositoryItem?: RepositoryItemListItem;
  seriesOnClick?: () => void;
  seasonOnClick?: () => void;
  titleOnClick?: () => void;
  titleClassName?: string;
}) => {
  const [isPopoverOpen, setIsPopoverOpen] = useState(false);
  const title: Title = {
    name: props.catalogTitle?.name ?? props.orderTitle?.name ?? props.repositoryItem?.title ?? '',
    isEpisode: [
      props.catalogTitle?.type,
      props.orderTitle?.titleType,
      props.repositoryItem?.itemType
    ].includes('EPISODE'),
    seriesName:
      props.catalogTitle?.series?.name ??
      props.orderTitle?.seriesName ??
      props.repositoryItem?.seriesTitle ??
      undefined,
    seasonNumber:
      props.orderTitle?.seasonNumber ??
      props.catalogTitle?.seasonNumber?.toString() ??
      props.repositoryItem?.seasonNumber?.toString() ??
      undefined,
    episodeNumber:
      props.orderTitle?.episodeNumber ??
      props.catalogTitle?.episodeNumber?.toString() ??
      props.repositoryItem?.episodeNumber?.toString() ??
      undefined
  };

  return (
    <div className="flex flex-col gap-1">
      <div className="flex flex-row items-center gap-1 text-gray">
        {title.isEpisode &&
          title.seriesName &&
          (props.seriesOnClick ? (
            <Button
              variant="titleLabelArrow"
              onClick={props.seriesOnClick}
              link
              className="underline text-primary p-0 justify-center items-center">
              {title.seriesName} <ArrowRightIcon className="w-4 h-4 mt-0.5 stroke-gray-400" />
            </Button>
          ) : (
            <>
              <span className="text-grey p-0 justify-center items-center">{title.seriesName} </span>
              <ArrowRightIcon className="w-4 h-4 mt-0.5 stroke-gray-400" />
            </>
          ))}

        {title.isEpisode &&
          title.seasonNumber &&
          (props.seasonOnClick ? (
            <Button
              variant="titleLabelArrow"
              onClick={props.seasonOnClick}
              link
              className="underline text-primary p-0 justify-center items-center">
              S{title.seasonNumber} <ArrowRightIcon className="w-4 h-4 mt-0.5 stroke-gray-400" />
            </Button>
          ) : (
            <>
              <span className="p-0 justify-center items-center">S{title.seasonNumber} </span>
              <ArrowRightIcon className="w-4 h-4 mt-0.5 stroke-gray-400" />
            </>
          ))}

        <span>
          {props.titleOnClick ? (
            <div className={'flex items-center'}>
              <Button
                onMouseEnter={() => setIsPopoverOpen(true)}
                onMouseLeave={() => setIsPopoverOpen(false)}
                variant="titleLabelArrow"
                onClick={props.titleOnClick}
                link
                className="underline text-gray  p-0 justify-center">
                <span className={clsx('font-bold', props.titleClassName)}>{title.name}</span>
                {title.isEpisode && title.episodeNumber && (
                  <span className="text-gray-500"> (E{title.episodeNumber})</span>
                )}
              </Button>
              <Popover open={isPopoverOpen}>
                <PopoverAnchor></PopoverAnchor>
                <PopoverContent
                  className={
                    'p-0 border-0 outline-none border-none relative transform -translate-y-3/4 translate-x-3/4 '
                  }
                  showArrow={false}>
                  <ExternalIcon className={'stroke-primary h-6 w-6'} />
                </PopoverContent>
              </Popover>
            </div>
          ) : (
            <>
              <span className="font-bold">{title.name}</span>
              {title.isEpisode && title.episodeNumber && (
                <span className="text-gray-500"> (E{title.episodeNumber})</span>
              )}
            </>
          )}
        </span>
      </div>
    </div>
  );
};
