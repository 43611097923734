import { createSlice } from '@reduxjs/toolkit';
import { RepositoryItemListItem } from './types';
import { Meta } from '../../common/types';

const initialState: { results: RepositoryItemListItem[]; meta: Meta | null } = {
  meta: {
    page: 1,
    pageSize: 25,
    total: 1
  },
  results: []
};

export const repositoryItemsSlice = createSlice({
  name: 'repositoryItemsSlice',
  initialState,
  reducers: {
    setItems: (state, action) => {
      return {
        ...action.payload
      };
    }
  }
});

export const { setItems } = repositoryItemsSlice.actions;

export default repositoryItemsSlice;
