import React, { ReactNode, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import Input from '../ui/Input';
import SearchIcon from '../../assets/icons/search.svg?react';
import CloseIcon from '../../assets/icons/close.svg?react';
import CheckIcon from '../../assets/icons/check.svg?react';
import {
  ListRepositoryItemsRequest,
  Repository,
  RepositoryItemQcStatusLabels,
  RepositoryLabels
} from '../../features/repository/types';
import Select from '../ui/Select';
import Button from '../ui/Button';
import { StudioLabels } from '../../common/types';
import { getOptions } from '../../common/utils';

export default function RepositorySearch({
  onSearch,
  distributor,
  search,
  qcStatus,
  repository,
  parentId,
  seasonNumber
}: {
  onSearch: (values: Record<string, string | any>) => void;
  distributor?: string;
  search?: string;
  qcStatus?: string;
  repository?: string;
  parentId?: string;
  seasonNumber?: number;
}) {
  const distributorOptions = getOptions(StudioLabels);
  const qcStatusOptions = getOptions(RepositoryItemQcStatusLabels);

  const RepositoryOptionRanking: Record<string, number> = {
    Movies: 1,
    Tv: 10,
    Shorts: 20,
    Standalone: 30
  };

  function sortOptions(a: Repository, b: Repository) {
    return (
      RepositoryOptionRanking[RepositoryLabels[a]] - RepositoryOptionRanking[RepositoryLabels[b]]
    );
  }
  const [repositoryOptions, setRepositoryOptions] = useState<{ value: string; label: string }[]>(
    distributor
      ? Object.keys(RepositoryLabels)
          .filter((repo) => repo.startsWith(distributor))
          .sort(sortOptions)
          .map((repo) => ({
            value: repo,
            label: RepositoryLabels[repo as Repository]
          }))
      : []
  );
  const { register, handleSubmit, setValue } = useForm<ListRepositoryItemsRequest>({
    defaultValues: {
      distributor: distributor,
      search: search,
      qcStatus: qcStatus,
      repository: repository
    }
  });

  function setValueAndSubmit(field: 'repository' | 'distributor' | 'qcStatus', value: any) {
    if (field === 'distributor' && distributor !== value) {
      setValue('repository', '');
    }

    setValue(field, value);
    handleSubmit(onSubmit)();
  }

  useEffect(() => {
    setValue('distributor', distributor);
    setRepositoryOptions(
      distributor
        ? Object.keys(RepositoryLabels)
            .filter((repo) => repo.startsWith(distributor))
            .sort(sortOptions)
            .map((repo) => ({
              value: repo,
              label: RepositoryLabels[repo as Repository]
            }))
        : []
    );
  }, [distributor]);
  useEffect(() => {
    setValue('repository', repository);
    setValue('search', search);
    setValue('qcStatus', qcStatus);
  }, [repository, search, qcStatus]);

  function onSubmit(
    values: ListRepositoryItemsRequest,
    _evt: React.BaseSyntheticEvent | undefined,
    cleanAction?: number
  ) {
    if (cleanAction === CLEAN.SEASON) {
      values.search = getSeriesTitle(search);
      values.parentId = parentId;
    } else if (cleanAction === CLEAN.PARENT) {
      delete values.search;
      setValue('search', '');
      values.repository = repository;
    } else {
      if (parentId) {
        values.parentId = parentId;
        values.search = search;
        if (seasonNumber) values.seasonNumber = seasonNumber;
      }
    }
    onSearch &&
      onSearch(
        Object.fromEntries(Object.entries(values).filter(([, value]) => value && value !== ''))
      );
  }

  const handleKeyDown = (event: React.KeyboardEvent) => {
    if (event.key === 'Enter') {
      handleSubmit(onSubmit);
    }
  };

  // Contextual Search Box:
  const isContextualSearch = search?.startsWith('series: ') && parentId;
  const seriesTitleExtractPattern = /series:\s*"([^"]+)"/g;

  const getSeriesTitle = (search?: string) => search?.match(seriesTitleExtractPattern)?.[0];

  return (
    <div>
      <search>
        <form
          onSubmit={handleSubmit(onSubmit)}
          className="container mx-auto flex flex-col items-stretch gap-2 border border-primary-300 bg-primary-200 p-3 rounded-lg">
          <div className="flex flex-row gap-2">
            <Select
              className="w-1/6"
              options={distributorOptions}
              isMulti={false}
              isClearable={false}
              placeholder="Distributor"
              onChange={(value: any) => setValueAndSubmit('distributor', value.value)}
              value={distributorOptions.find((opt) => opt.value === distributor)}
            />

            <div className="basis-full">
              <div className={'relative'}>
                {!isContextualSearch && (
                  <Input
                    rounded
                    className={'grow'}
                    defaultValue={search}
                    prefixNode={<SearchIcon className="stroke-gray-400" />}
                    placeholder="Title / EIDR / Catalog ID / Parent title"
                    onKeyDown={handleKeyDown}
                    {...register('search')}
                    onBlur={handleSubmit(onSubmit)}
                  />
                )}

                {isContextualSearch && (
                  <div className={'relative '}>
                    <div className={'absolute inset-y-0 left-0 pl-3 flex items-center'}>
                      <SearchIcon className="stroke-gray-400" />
                    </div>
                    <div
                      className={
                        'grow rounded-lg border text-gray-900 border-gray-300  min-h-12 py-2 px-3  bg-white  w-full h-full flex flex-wrap gap-2 items-center pl-10 '
                      }>
                      <Chip
                        OnClick={handleSubmit((args, evt) => onSubmit(args, evt, CLEAN.PARENT))}>
                        {getSeriesTitle(search)}
                      </Chip>

                      {seasonNumber && (
                        <Chip
                          OnClick={handleSubmit((args, evt) => onSubmit(args, evt, CLEAN.SEASON))}>
                          season: {seasonNumber}
                        </Chip>
                      )}
                    </div>
                  </div>
                )}
              </div>
            </div>
            <Button rounded variant="primary">
              Search
            </Button>
          </div>

          <div className="w-full">
            <div className="flex flex-row gap-4 flex-wrap">
              <div className="grow flex flex-row gap-2">
                <FilterItem
                  title="All titles"
                  onClick={() => setValueAndSubmit('repository', '')}
                  suffixNode={!repository && <CheckIcon className="w-5 h-5 stroke-primary" />}
                />
                {repositoryOptions.map((repo) => (
                  <FilterItem
                    key={repo.value}
                    title={repo.label}
                    onClick={() => setValueAndSubmit('repository', repo.value)}
                    suffixNode={
                      repository === repo.value && <CheckIcon className="w-5 h-5 stroke-primary" />
                    }
                  />
                ))}
              </div>
              <div className="w-full max-w-sm min-w-sm">
                <Select
                  options={qcStatusOptions}
                  isClearable={true}
                  placeholder="ToDO"
                  onChange={(value: any) => setValueAndSubmit('qcStatus', value?.value)}
                  value={qcStatusOptions.find((opt) => opt?.value === qcStatus) ?? ''}
                />
              </div>
            </div>
          </div>
        </form>
      </search>
    </div>
  );
}

const FilterItem = ({
  title,
  count,
  prefixNode,
  suffixNode,
  onClick,
  disabled = false
}: {
  title: string;
  count?: number;
  prefixNode?: ReactNode;
  suffixNode?: ReactNode;
  onClick?: () => void;
  disabled?: boolean;
}) => {
  return (
    <Button rounded className="bg-white" onClick={onClick} disabled={disabled}>
      <div className="flex flex-row gap-2 items-center">
        {prefixNode}
        <span className="font-semibold text-gray-700">{title}</span>
        {typeof count !== 'undefined' && count !== 0 && (
          <span className="text-gray-400">({count})</span>
        )}
        {suffixNode}
      </div>
    </Button>
  );
};

const Chip = ({ children, OnClick }: { children: React.ReactNode; OnClick: () => void }) => {
  return (
    <span className="border  border-primary-600  rounded-lg  py-1 px-3  text-sm relative pe-9 bg-primary-600 text-white">
      {children}
      <div className={'absolute right-0 bottom-0 top-0 w-7 flex items-center'}>
        <button
          type="button"
          onClick={OnClick}
          className="border rounded-full w-5 h-5 text-[12px] flex items-center justify-center cursor-pointer hover:border-white hover:bg-white stroke-white hover:stroke-primary transition-all">
          <CloseIcon className="w-3 h-3" />
        </button>
      </div>
    </span>
  );
};

const CLEAN = {
  SEASON: 1,
  PARENT: 2
} as const;
