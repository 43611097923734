import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../app/store';
import TreeViewNodeAssetTemplate, {
  TreeViewNodeAssetTemplateProps
} from './TreeViewNodeAssetTemplate';
import TreeView, { findTreeViewNodeById, findTreeViewRootNode, TreeViewNode } from '../ui/TreeView';
import React, { useEffect } from 'react';
import { Asset } from '../../features/repository/types';
import {
  setActiveAsset,
  setBaselineVideoAsset
} from '../../features/repository/repositoryItemSlice';

/**
 * Maps an Asset to the TreeViewNode props required for rendering in TreeView.
 */
export function mapTreeViewNodeAssetPropsFromAsset(
  asset: Asset
): TreeViewNode<TreeViewNodeAssetTemplateProps, string> {
  return {
    id: asset._id,
    value: {
      filename: asset.location.split('/').pop() ?? '',
      location: asset.location,
      language: asset.language,
      message: asset.comment,
      status: asset.status,
      type: asset.type
    }
  };
}

/**
 * Maps array of assets to the tree data format required by the TreeView component,
 * placing sidecar files under their VIDEO file if its status is ACCEPTED.
 */
function prepareTreeViewData(
  assets: Asset[],
  setTreeData: (data: TreeViewNode<TreeViewNodeAssetTemplateProps, string>[]) => void
) {
  const sidecarFiles: TreeViewNode<TreeViewNodeAssetTemplateProps, string>[] = assets
    .filter((file) => file.type !== 'VIDEO')
    .map(mapTreeViewNodeAssetPropsFromAsset)
    .sort(sortByLanguageThenFiletype);

  const videoNodes: TreeViewNode<TreeViewNodeAssetTemplateProps, string>[] = assets
    .filter((file) => file.type === 'VIDEO')
    .map((asset) => {
      const node = mapTreeViewNodeAssetPropsFromAsset(asset);
      node.children = asset.status === 'ACCEPTED' ? sidecarFiles : [];
      return node;
    });

  setTreeData(videoNodes);
}

/*
 *  Main component
 */
function RepositoryItemAssetsTreeView({ setNextAsset }: { setNextAsset: (asset: Asset) => void }) {
  const dispatch = useDispatch();
  const { repositoryItem } = useSelector((state: RootState) => state.repositoryItem);
  const [treeData, setTreeData] = React.useState<
    TreeViewNode<TreeViewNodeAssetTemplateProps, string>[]
  >([]);

  const [selectedNodeId, setSelectedNodeId] = React.useState<string | null>(null);

  useEffect(() => {
    if (repositoryItem?.assets) {
      prepareTreeViewData(repositoryItem.assets, setTreeData);
    }
  }, [repositoryItem?.assets]);

  // Default Node Selection
  useEffect(() => {
    if (!repositoryItem) return;
    if (!selectedNodeId && treeData) {
      setSelectedNodeId(getDefaultNodeId(treeData));
    } else {
      const selectedNode = findTreeViewNodeById(treeData, selectedNodeId);
      if (!selectedNode) return;

      // dispatch the videoAsset and sidecar file to the store
      const activeAsset = repositoryItem?.assets.find((asset) => asset._id === selectedNode.id);

      // If asset is not a video, find the video asset and store it
      if (activeAsset && activeAsset.type !== 'VIDEO') {
        const activeVideoAsset = findTreeViewRootNode(treeData, selectedNodeId);
        if (!activeVideoAsset) return;
        const videoAsset = repositoryItem?.assets.find(
          (asset) => asset._id === activeVideoAsset.id
        );
        dispatch(setBaselineVideoAsset(videoAsset));

        // find sibling sidecar file
        const nextSiblingIndex =
          activeVideoAsset.children!.findIndex((child) => child.id === selectedNodeId) + 1;
        if (nextSiblingIndex < activeVideoAsset.children!.length) {
          const nextAsset = repositoryItem?.assets.find(
            (asset) => asset._id === activeVideoAsset.children![nextSiblingIndex].id
          );
          console.log(nextAsset);
          if (nextAsset) {
            setNextAsset(nextAsset);
          }
        }
      } else {
        // find the first child of the video asset
        const firstChild = selectedNode?.children?.[0];
        if (firstChild) {
          const nextAsset = repositoryItem.assets.find(
            (asset) => asset._id === firstChild.id
          ) as Asset;
          setNextAsset(nextAsset);
        }
      }
      dispatch(setActiveAsset(activeAsset));
    }
  }, [selectedNodeId, treeData]);

  return (
    <TreeView
      data={treeData}
      selected={selectedNodeId}
      setSelected={setSelectedNodeId}
      render={(value) => <TreeViewNodeAssetTemplate asset={value} />}
      onSelectBehaviour={'collapse'}
    />
  );
}

export default RepositoryItemAssetsTreeView;

/*--------- HELPERS ----------*/

/**
 * Sorts TreeView nodes first by type, then by language.
 */
function sortByLanguageThenFiletype(
  a: TreeViewNode<TreeViewNodeAssetTemplateProps, string>,
  b: TreeViewNode<TreeViewNodeAssetTemplateProps, string>
) {
  // Compare types
  if (a.value.type < b.value.type) return -1;
  if (a.value.type > b.value.type) return 1;

  // If types are the same, compare languages
  if (a.value.language < b.value.language) return -1;
  if (a.value.language > b.value.language) return 1;
  return 0;
}

/**
 * Returns the first video node that is accepted, to conform, or new.
 */
const getDefaultNodeId = (treeData: TreeViewNode<TreeViewNodeAssetTemplateProps, string>[]) => {
  // Search for a Video by this order: ACCEPTED, TO_CONFORM, NEW

  const videoTypes = ['ACCEPTED', 'TO_CONFORM', 'NEW'];
  // sort the nodes by status
  const sortedNodes = treeData.sort(
    (a, b) => videoTypes.indexOf(a.value.status ?? '') - videoTypes.indexOf(b.value.status ?? '')
  );

  // get the first node
  return sortedNodes[0]?.id;
};
