import { Helmet } from 'react-helmet-async';
import React, { ReactElement, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import Breadcrumbs, { BreadcrumbsItem } from '../../components/ui/Breadcrumbs';

import Button from '../../components/ui/Button';
import HomeIcon from '../../assets/icons/home.svg?react';

import UploadIcon from '../../assets/icons/upload.svg?react';
import ZapIcon from '../../assets/icons/zap-fast.svg?react';
import SearchIcon from '../../assets/icons/search.svg?react';
import { ToggleGroup, ToggleGroupItem } from '../../components/ui/ToggleGroup';
import Skeleton from '../../components/ui/Skeleton';
import Table from '../../components/ui/Table';
import {
  Message,
  RepositoryItemHistory
} from '../../components/repository-details/RepositoryItemHistory';
import MetadataRichDetailPageHeader from '../../components/repository-details/MetadataRichDetailPageHeader';
import FileTypeIcon from '../../components/repository/FileTypeIcon';
import { Popover, PopoverAnchor, PopoverContent } from '../../components/ui/Popover';
import { RootState } from '../../app/store';
import { HistoryLog } from '../../common/types';
import { Asset, AssetStatusLabels } from '../../features/repository/types';
import { getRepositoryItem } from '../../features/repository/repositoryApi';
import {
  addRepositoryItemHistoryMessage,
  setRepositoryItem
} from '../../features/repository/repositoryItemSlice';
import EditRepositoryItemExternalIdsForm from '../../components/repository-details/EditRepositoryItemExternalIdsForm';
import { twJoin } from 'tailwind-merge';
import Layout from '../../components/repository-details/Layout';

export default function MetadataRichDetailPage() {
  const dispatch = useDispatch();
  const { repositoryItemId } = useParams();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isHistoryVisible, setIsHistoryVisible] = useState<boolean>(false);

  const { repositoryItem } = useSelector((state: RootState) => state.repositoryItem);

  const getData = async () => {
    if (!repositoryItemId) return;
    setIsLoading(true);
    try {
      const repositoryItem = await getRepositoryItem(repositoryItemId);
      dispatch(setRepositoryItem(repositoryItem));
    } catch (error) {
      console.error('Error fetching repository item:', error);
    }
    setIsLoading(false);
  };

  useEffect(() => {
    getData();
  }, [repositoryItemId]);
  const breadcrumbItems: BreadcrumbsItem[] = [
    {
      link: '/',
      icon: <HomeIcon />
    },
    {
      title: 'Repository',
      link:
        '/repository' +
        (repositoryItem &&
          `?distributor=${repositoryItem.repository.split('__')[0]}&repository=${repositoryItem.repository}`)
    },
    {
      title: 'Edit details'
    }
  ];

  function mapRepositoryItemHistory(history: HistoryLog[]): Message[] {
    return history.map((item) => {
      return {
        sender: item.action === 'NEW_USER_MESSAGE' ? 'user' : 'system',
        message: item.message,
        sentAt: item.timestamp,
        senderName: item.userFullName
      };
    });
  }

  function getFormattedDate() {
    const now = new Date();

    // Get ISO string in the format "2024-06-11T07:59:18.804Z"
    let isoString = now.toISOString();

    // Extract the milliseconds part
    const milliseconds = now.getMilliseconds();

    // Pad milliseconds to 3 digits (e.g., 804 becomes 804000)
    const microseconds = String(milliseconds).padStart(3, '0') + '000';

    // Replace the milliseconds with the microsecond value
    isoString = isoString.replace(/\.\d{3}Z$/, `.${microseconds}Z`);

    return isoString;
  }

  function addMessage(msg: string) {
    console.log('Adding message:', getFormattedDate());
    const newMessage: HistoryLog = {
      user: 'user',
      userFullName: 'John Doe',
      userOrganization: 'org',
      userOrganizationName: 'Organization',
      action: 'NEW_USER_MESSAGE',
      message: msg,
      timestamp: getFormattedDate()
    };
    // Add message to the state
    dispatch(addRepositoryItemHistoryMessage(newMessage));
  }

  return (
    <>
      <Helmet>
        <title>{`Above Media | Repository | Details `}</title>
      </Helmet>

      <Breadcrumbs items={breadcrumbItems} />

      <Layout expanded={!isHistoryVisible}>
        <Layout.Content>
          <MetadataRichDetailPageHeader
            title={repositoryItem?.title || ''}
            titleType={repositoryItem?.itemType || ''}
            historyVisible={isHistoryVisible}
            setHistoryVisible={setIsHistoryVisible}
          />
          <MetadataRichDetailPageContent isLoading={isLoading} />
        </Layout.Content>

        <Layout.RightSide>
          <RepositoryItemHistory
            onClose={() => setIsHistoryVisible(false)}
            messages={
              (repositoryItem?.history && mapRepositoryItemHistory(repositoryItem?.history)) || []
            }
            /*onSubmittedMessage={(msg) => addMessage(msg)}*/
          />
        </Layout.RightSide>
      </Layout>
    </>
  );
}

function MetadataRichDetailPageContent({
  className,
  isLoading
}: {
  className?: string;
  isLoading: boolean;
}) {
  const [selectedRows, setSelectedRows] = useState<Set<number>>(new Set());
  const [activeAction, setActiveAction] = useState<string>('');
  const { repositoryItem } = useSelector((state: RootState) => state.repositoryItem);

  /* BEGIN ActionHandlers */
  const reIngestAssets = () => {
    //convert set<number> to set<string>
    const selectedRowIds = new Set<string>([...selectedRows].map((row) => row.toString()));
    const selectedAssetLocations = repositoryItem?.assets
      .filter((asset) => selectedRowIds.has(asset._id))
      .map((asset) => asset.location);

    const queryParams: { path?: string; mpm?: string; editVersion?: string } = {};

    if (selectedAssetLocations) {
      queryParams.path = selectedAssetLocations.join('\n');
    }
    if (
      repositoryItem?.catalogIdType &&
      repositoryItem?.catalogIdType === 'MPM' &&
      repositoryItem?.titleCatalogId
    ) {
      queryParams.mpm = repositoryItem?.titleCatalogId;
    }
    if (repositoryItem?.editVersion) {
      queryParams.editVersion = repositoryItem?.editVersion;
    }
    const baseUrl = '/ingestion/new/file-locations?';
    window.open(baseUrl + new URLSearchParams(queryParams).toString(), '_blank');
    setActiveAction('');
  };
  /* BEGIN MUTATIONS */
  const recoverAssets = () => {};
  const fastRecoverAssets = () => {};
  const deleteAssets = () => {};
  /* END MUTATIONS */

  const iconClasses = 'stroke-gray-400 h-4 w-4';
  const TableActions = () => {
    const ToggleGroupItemWithConfirmation = ({
      value,
      onAccept,
      onReject,
      disabled = false,
      promptMessage,
      children
    }: {
      value: string;
      onAccept: () => void;
      onReject: () => void;
      disabled?: boolean;
      promptMessage: string;
      children: ReactElement;
    }) => {
      return (
        <ToggleGroupItem value={value} disabled={selectedRows.size == 0 || disabled}>
          <span className={'relative'}>
            <Popover
              open={activeAction == value}
              defaultOpen={false}
              onOpenChange={(val) => !val && onReject()}>
              <PopoverAnchor>{children}</PopoverAnchor>
              <PopoverContent className={'bg-white rounded-md p-4 border border-gray-400'}>
                <div onClick={(evt) => evt.stopPropagation()}>
                  <div className={'text-sm text-gray-900 max-w-48'}>{promptMessage}</div>
                  <div className={'flex gap-2 mt-2 justify-end'}>
                    <Button variant={'primary'} onClick={onAccept} rounded size={'xs'}>
                      Yes
                    </Button>
                    <Button variant={'defaultOutlined'} onClick={onReject} rounded size={'xs'}>
                      No
                    </Button>
                  </div>
                </div>
              </PopoverContent>
            </Popover>
          </span>
        </ToggleGroupItem>
      );
    };
    const resetActionState = () => setActiveAction('');

    return (
      <div className={`w-max ${selectedRows.size == 0 && 'opacity-30'}`}>
        <ToggleGroup
          defaultValue=""
          value={activeAction}
          onValueChange={(value) => setActiveAction(value)}
          type="single">
          <ToggleGroupItemWithConfirmation
            value="RE_INGEST"
            onAccept={reIngestAssets}
            onReject={resetActionState}
            promptMessage={'Are you sure you want to Re-Ingest selected assets?'}>
            <span className={'flex gap-2 items-center'}>
              <UploadIcon className={'stroke-gray-400 h-4 w-4'} />
              Re-ingest
            </span>
          </ToggleGroupItemWithConfirmation>

          {/*<ToggleGroupItemWithConfirmation
            value="RECOVER"
            onAccept={RecoverAssets}
            onReject={ResetActionState}
            promptMessage={'Are you sure you want to Recover selected assets?'}>
            <span className={'flex gap-2 items-center'}>
              <DownloadCloudIcon className={iconClasses} />
              Recover
            </span>
          </ToggleGroupItemWithConfirmation>*/}

          <ToggleGroupItemWithConfirmation
            value="FAST_RECOVER"
            onAccept={fastRecoverAssets}
            onReject={resetActionState}
            disabled
            promptMessage={'Are you sure you want to Fast Recover selected assets?'}>
            <span className={'flex gap-2 items-center opacity-50 cursor-not-allowed'}>
              <ZapIcon className={iconClasses} />
              Fast Recover
            </span>
          </ToggleGroupItemWithConfirmation>

          {/*<ToggleGroupItemWithConfirmation
            value="DELETE"
            onAccept={DeleteAssets}
            onReject={ResetActionState}
            promptMessage={'Are you sure you want to DELETE selected assets?'}>
            <span className={'flex gap-2 items-center'}>
              <DeleteIcon className={iconClasses} />
              Delete
            </span>
          </ToggleGroupItemWithConfirmation>*/}
        </ToggleGroup>
      </div>
    );
  };

  const getFileIcon = (fileExtension?: string): ReactElement => (
    <FileTypeIcon extension={fileExtension?.toLowerCase() ?? ''} />
  );
  const columns = [
    {
      key: '_id',
      title: 'File name',
      className: 'w-auto',
      render: (item: Asset) => {
        const fileName = item.location?.split('/').pop();
        const fileExtension = fileName?.split('.').pop();
        //Convert to MB
        const fileSize = (item.size / (1024 * 1024)).toFixed(2) + ' MB';
        return (
          <div className="flex gap-3 items-center">
            <div>{getFileIcon(fileExtension)}</div>
            <div>
              <p>{fileName}</p>
              <p className="text-gray-600 text-sm">{fileSize}</p>
            </div>
          </div>
        );
      }
    },
    {
      key: 'ingested_at',
      title: 'Ingested at',
      className: 'w-36',
      render: (item: Asset) => (
        <div className="text-gray-600">
          {/*Jan 2, 2024*/}
          {item.createdAt
            ? new Date(item.createdAt).toLocaleDateString('en-US', {
                year: 'numeric',
                month: 'short',
                day: '2-digit'
              })
            : ''}
        </div>
      )
    },
    {
      key: 'status',
      title: 'Status',
      className: 'w-36',
      render: (item: Asset) => AssetStatusLabels[item.status]
    }
  ];

  return (
    <div className={'px-6'}>
      {isLoading && (
        <div className={twJoin('w-full', className)}>
          <div className="absolute top-0 left-0 right-0 bottom-0 bg-white bg-opacity-50"></div>
          <div className="border-t border-gray-100">
            <Skeleton />
          </div>
        </div>
      )}
      {!isLoading && (
        <>
          {/** Repository Item Details */}
          <div className="ml-8 my-8 mr-4">
            <EditRepositoryItemExternalIdsForm />
          </div>
          {/** Assets Grid & Actions */}
          <div className={'my-8'}>
            {/** ACTIONS BAR **/}
            <div className={'flex justify-end mb-2.5'}>
              <TableActions />
            </div>
            {/** ASSETS GRID **/}
            <div>
              <Table
                multipleSelection
                selectedRows={selectedRows}
                onSelectChange={(selected) => setSelectedRows(selected)}
                data={repositoryItem?.assets || []}
                columns={columns}
                rowId="_id"
                disabledRows={[]}
                rounded
                pagination={{
                  currentPage: 1,
                  totalItems: 3,
                  itemsPerPage: 10,
                  onPageChange: () => undefined
                }}
                noDataIcon={<SearchIcon className="w-8 h-8 stroke-gray-400" />}
                noDataLabel="No assets were found!"
                noDataDescription="There are no assets available for this title."
              />
            </div>
          </div>
        </>
      )}
    </div>
  );
}
