import { graphql } from 'gql.tada';

/* usage: Repository Item Metadata Rich Detail Page */

export const updateRepositoryItemCatalogIdAndEidr = graphql(`
  mutation updateRepositoryItemCatalogIdAndEidr(
    $repositoryItemId: ID!
    $newCatalogId: String
    $newCatalogIdType: CatalogIdType
    $newEidr: String
    $updateAll: Boolean
    $updateOne: Boolean
  ) {
    updateRepositoryItemCatalogIdAndEidr(
      repositoryItemId: $repositoryItemId
      newCatalogId: $newCatalogId
      newCatalogIdType: $newCatalogIdType
      newEidr: $newEidr
      updateOne: $updateOne
      updateAll: $updateAll
    ) {
      updatedOne
      updatedAll
      itemsWithSameCatalogId {
        _id
        title
        itemType
        repository
        eidrId
        titleCatalogId
        catalogIdType
        editVersion
        editEidrId
        folderLocation
        parentId
        seasonNumber
        episodeNumber
        releaseDate
        createdAt
        modifiedAt
        history {
          user
          userFullName
          userOrganization
          userOrganizationName
          action
          message
          timestamp
        }
      }
      itemsWithSameEidr {
        _id
        title
        itemType
        repository
        eidrId
        titleCatalogId
        catalogIdType
        editVersion
        editEidrId
        folderLocation
        parentId
        seasonNumber
        episodeNumber
        releaseDate
        createdAt
        modifiedAt
        history {
          user
          userFullName
          userOrganization
          userOrganizationName
          action
          message
          timestamp
        }
      }
    }
  }
`);

/* usages: Repository Item Quality Control */

export const updateAssetComment = graphql(`
  mutation updateAssetComment($_id: ID!, $comment: String!) {
    updateAssetComment(_id: $_id, comment: $comment) {
      _id
      location
      type
      status
      language
      tracks {
        index
        trackType
        language
        enabled
      }
      repositoryItemId
      size
      mediainfo
      history {
        user
        userFullName
        userOrganization
        userOrganizationName
        action
        message
        timestamp
      }
      statusModifiedAt
      modifiedAt
      createdAt
    }
  }
`);

export const updateAssetStatus = graphql(`
  mutation updateAssetStatus($_id: ID!, $status: AssetStatus!) {
    updateAssetStatus(_id: $_id, status: $status) {
      _id
      location
      type
      status
      language
      tracks {
        index
        trackType
        language
        enabled
      }
      repositoryItemId
      size
      mediainfo
      history {
        user
        userFullName
        userOrganization
        userOrganizationName
        action
        message
        timestamp
      }
      statusModifiedAt
      modifiedAt
      createdAt
    }
  }
`);

export const addAssetQcReport = graphql(`
  mutation addAssetQCReport($_id: ID!, $report: AssetQCReportInput!, $force: Boolean!) {
    addAssetQCReport(_id: $_id, report: $report, force: $force) {
      added
      reportId
      existingAcceptedAsset {
        _id
        location
        type
        status
        language
        tracks {
          index
          trackType
          language
          enabled
        }
        repositoryItemId
        size
        mediainfo
        history {
          user
          userFullName
          userOrganization
          userOrganizationName
          action
          message
          timestamp
        }
        statusModifiedAt
        modifiedAt
        createdAt
      }
    }
  }
`);
