import { ReactNode, useEffect, useRef } from 'react';
import CloseIcon from '../../assets/icons/close.svg?react';
import clsx from 'clsx';

export interface ModalProps {
  title?: ReactNode;
  footer?: ReactNode;
  onClose?: () => void;
  visible: boolean;
  setVisible: (value: boolean) => void;
  children: ReactNode;
  showHeader?: boolean;
  className?: string;
  onOverlayClick?: () => void;
  position?: 'center' | 'top';
}

const Modal = ({
  title,
  footer,
  onClose,
  visible,
  setVisible,
  children,
  showHeader = true,
  className,
  onOverlayClick = () => null,
  position = 'top'
}: ModalProps) => {
  // Use useEffect to add an event listener to the document
  useEffect(() => {
    if (!visible) return;
    function onKeyDown(event: KeyboardEvent) {
      if (event.key === 'Enter') {
        // Close the modal when the Escape key is pressed
        onClose && onClose();
      }
    }

    // Prevent scolling
    document.body.style.overflow = 'hidden';
    // Prevent scrolling on <html> for Safari
    document.documentElement.style.overflow = 'hidden';
    document.addEventListener('keydown', onKeyDown);

    // Clear things up when unmounting this component
    return () => {
      document.body.style.overflow = 'visible';
      document.documentElement.style.overflow = 'visible';
      document.removeEventListener('keydown', onKeyDown);
    };
  }, [visible]);

  if (visible) {
    return (
      <div
        className="fixed overflow-auto left-0 bottom-0 top-0 right-0 z-10 bg-black bg-opacity-70"
        onClick={onOverlayClick}>
        <div
          onClick={(evt) => evt.stopPropagation()}
          className={clsx(
            {
              'bg-white rounded-xl max-w-full mx-auto my-14 w-4/5 lg:w-2/5 relative ':
                position === 'top',
              ' absolute inset-0 bg-white rounded-xl left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2 max-h-dvh max-w-[100dvw] overflow-auto w-max h-max':
                position === 'center'
            },
            className
          )}>
          {showHeader && (
            <div className="flex flex-row border-b border-gray-100 p-4">
              <div className="grow font-semibold text-gray-900">{title}</div>
              <div className="flex-none cursor-pointer" onClick={() => setVisible(false)}>
                <CloseIcon className="h-6 w-6 stroke-gray-400" />
                <span className="sr-only">Close</span>
              </div>
            </div>
          )}
          {children}
          {footer}
        </div>
      </div>
    );
  }

  return null;
};

export default Modal;
