import { Asset, MediaInfoAudio, MediaInfoSubtitle, MediaInfoVideo } from './types';

export interface QCCheck {
  id: string;
  playerCommand?: 'SEEK_TO_25' | 'SEEK_TO_50' | 'SEEK_TO_75';
  question: string;
  options?: { answer: string; value: string; verdict: 'PASSED' | 'FAILED' | 'WARNING' }[];
}

export interface QCAutoCheck extends QCCheck {
  check: (baselineVideoAsset?: Asset, asset?: Asset) => string[];
}

export const qcChecks: {
  audio: { auto: QCAutoCheck[]; manual: QCCheck[] };
  video: { auto: QCAutoCheck[]; manual: QCCheck[] };
  subtitle: { auto: QCAutoCheck[]; manual: QCCheck[] };
  closedcaption: { auto: QCAutoCheck[]; manual: QCCheck[] };
} = {
  video: {
    auto: [
      {
        id: 'AUTO_VIDEO_QUALITY_CHECK',
        question: 'Check video quality',
        options: [
          { answer: 'Good', value: 'GOOD', verdict: 'PASSED' },
          { answer: 'Poor', value: 'POOR', verdict: 'FAILED' }
        ],
        check: (baselineVideoAsset?: Asset, asset?: Asset) => {
          const mediaInfo = getMediaInfo(asset) as MediaInfoVideo;
          if (!mediaInfo) return [];

          const videoQuality = GetVideoQuality(mediaInfo);
          if (videoQuality && AcceptedVideoQualities.includes(videoQuality)) return ['GOOD'];
          else return ['POOR'];
        }
      },
      {
        id: 'AUTO_VIDEO_ASPECT_RATIO_CHECK',
        question: 'Check video aspect ratio',
        options: [
          { answer: 'Ok', value: 'OK', verdict: 'PASSED' },
          { answer: 'Not ok', value: 'NOT_OK', verdict: 'FAILED' }
        ],
        check: (baselineVideoAsset?: Asset, asset?: Asset) => {
          const mediaInfo = getMediaInfo(asset) as MediaInfoVideo;
          if (!mediaInfo) return [];

          const aspectRatio = GetAspectRatio(mediaInfo);
          if (aspectRatio && AcceptedAspectRatios.includes(aspectRatio)) return ['OK'];
          else return ['NOT_OK'];
        }
      },
      {
        id: 'AUTO_VIDEO_BIT_RATE_CHECK',
        question: 'Check video bit rate',
        options: [
          { answer: 'Ok', value: 'OK', verdict: 'PASSED' },
          { answer: 'Not ok', value: 'NOT_OK', verdict: 'FAILED' }
        ],
        check: (baselineVideoAsset?: Asset, asset?: Asset) => {
          const mediaInfo = getMediaInfo(asset) as MediaInfoVideo;
          if (!mediaInfo) return [];

          if (IsVideoBitrateGood(mediaInfo)) return ['OK'];
          else return ['NOT_OK'];
        }
      }
    ],
    manual: [
      {
        id: 'MANUAL_VIDEO_QUALITY_CHECK',
        question: 'Make sure the quality of the video is acceptable',
        options: [
          { answer: 'In sync', value: 'IN_SYNC', verdict: 'PASSED' },
          { answer: 'Out of sync', value: 'OUT_OF_SYNC', verdict: 'FAILED' }
        ]
      },
      {
        id: 'MANUAL_VIDEO_25_CHECK',
        playerCommand: 'SEEK_TO_25',
        question: 'Check video 25% into the asset',
        options: [
          { answer: 'In sync', value: 'IN_SYNC', verdict: 'PASSED' },
          { answer: 'Out of sync', value: 'OUT_OF_SYNC', verdict: 'FAILED' }
        ]
      },
      {
        id: 'MANUAL_VIDEO_50_CHECK',
        playerCommand: 'SEEK_TO_50',
        question: 'Check video 50% into the asset',
        options: [
          { answer: 'In sync', value: 'IN_SYNC', verdict: 'PASSED' },
          { answer: 'Out of sync', value: 'OUT_OF_SYNC', verdict: 'FAILED' }
        ]
      },
      {
        id: 'MANUAL_VIDEO_75_CHECK',
        playerCommand: 'SEEK_TO_75',
        question: 'Check video 75% into the asset',
        options: [
          { answer: 'In sync', value: 'IN_SYNC', verdict: 'PASSED' },
          { answer: 'Out of sync', value: 'OUT_OF_SYNC', verdict: 'FAILED' }
        ]
      }
    ]
  },
  audio: {
    auto: [
      /*{
        id: 'AUTO_LANG_CHECK',
        question: 'Check correct language',
        options: [
          { answer: 'Correct', value: 'CORRECT', verdict: 'PASSED' },
          { answer: 'Incorrect', value: 'INCORRECT', verdict: 'FAILED' }
        ],
        check: (baselineVideoAsset?: Asset, asset?: Asset) => {
          if (!asset) return [];
          if (asset.mediainfo?.length > 0) {
            console.log('Checking audio');
            asset.mediainfo.map((info) => {
              console.log(JSON.parse(info));
            });
          }
          return ['CORRECT'];
        }
      },*/
      {
        id: 'AUTO_AUDIO_DURATION_CHECK',
        question: 'Check audio duration',
        options: [
          { answer: 'Ok', value: 'OK', verdict: 'PASSED' },
          { answer: 'Not ok', value: 'NOT_OK', verdict: 'FAILED' }
        ],
        check: (baselineVideoAsset?: Asset, asset?: Asset) => {
          const audioInfo = getMediaInfo(asset) as MediaInfoAudio;
          const videoInfo = getMediaInfo(baselineVideoAsset) as MediaInfoVideo;
          if (!audioInfo || !videoInfo) return [];

          return doesAudioDurationFitVideo(videoInfo, audioInfo) ? ['OK'] : ['NOT_OK'];
        }
      }
    ],
    manual: [
      {
        id: 'MANUAL_AUDIO_SYNC_25_CHECK',
        playerCommand: 'SEEK_TO_25',
        question: 'Check audio sync 25% into the asset',
        options: [
          { answer: 'In sync', value: 'IN_SYNC', verdict: 'PASSED' },
          { answer: 'Out of sync', value: 'OUT_OF_SYNC', verdict: 'FAILED' }
        ]
      },
      {
        id: 'MANUAL_AUDIO_50_CHECK',
        playerCommand: 'SEEK_TO_50',
        question: 'Check audio sync 50% into the asset',
        options: [
          { answer: 'In sync', value: 'IN_SYNC', verdict: 'PASSED' },
          { answer: 'Out of sync', value: 'OUT_OF_SYNC', verdict: 'FAILED' }
        ]
      },
      {
        id: 'MANUAL_AUDIO_75_CHECK',
        playerCommand: 'SEEK_TO_75',
        question: 'Check audio sync 75% into the asset',
        options: [
          { answer: 'In sync', value: 'IN_SYNC', verdict: 'PASSED' },
          { answer: 'Out of sync', value: 'OUT_OF_SYNC', verdict: 'FAILED' }
        ]
      }
    ]
  },
  subtitle: {
    auto: [
      /*{
        id: 'AUTO_SUBTITLE_DURATION_CHECK',
        question: 'Does all subtitles fit within the video duration?',
        options: [
          { answer: 'Ok', value: 'OK', verdict: 'PASSED' },
          { answer: 'Not ok', value: 'NOT_OK', verdict: 'FAILED' }
        ],
        check: (baselineVideoAsset?: Asset, asset?: Asset) => {
          if (!asset) return [];
          if (asset.mediainfo?.length > 0) {
            console.log('Checking subtitle');
            asset.mediainfo.map((info) => {
              console.log(JSON.parse(info));
            });
          }

          const baselineDuration = findPropertyAboveValue(
            baselineVideoAsset?.mediainfo,
            'Duration'
          );
          const duration = findPropertyAboveValue(asset.mediainfo, 'Duration');

          return baselineDuration === duration ? ['OK'] : ['NOT_OK'];
        }
      }*/
    ],
    manual: [
      {
        id: 'MANUAL_SUBTITLE_SYNC_25_CHECK',
        playerCommand: 'SEEK_TO_25',
        question: 'Check subtitle sync 25% into the asset',
        options: [
          { answer: 'In sync', value: 'IN_SYNC', verdict: 'PASSED' },
          { answer: 'Out of sync', value: 'OUT_OF_SYNC', verdict: 'FAILED' }
        ]
      },
      {
        id: 'MANUAL_SUBTITLE_50_CHECK',
        playerCommand: 'SEEK_TO_50',
        question: 'Check subtitle sync 50% into the asset',
        options: [
          { answer: 'In sync', value: 'IN_SYNC', verdict: 'PASSED' },
          { answer: 'Out of sync', value: 'OUT_OF_SYNC', verdict: 'FAILED' }
        ]
      },
      {
        id: 'MANUAL_SUBTITLE_75_CHECK',
        playerCommand: 'SEEK_TO_75',
        question: 'Check subtitle sync 75% into the asset',
        options: [
          { answer: 'In sync', value: 'IN_SYNC', verdict: 'PASSED' },
          { answer: 'Out of sync', value: 'OUT_OF_SYNC', verdict: 'FAILED' }
        ]
      }
    ]
  },
  closedcaption: {
    auto: [
      /*{
        id: 'AUTO_CLOSED_CAPTION_DURATION_CHECK',
        question: 'Does all captions fit within the video duration?',
        options: [
          { answer: 'Ok', value: 'OK', verdict: 'PASSED' },
          { answer: 'Not ok', value: 'NOT_OK', verdict: 'FAILED' }
        ],
        check: (baselineVideoAsset?: Asset, asset?: Asset) => {
          if (!asset) return [];
          if (asset.mediainfo?.length > 0) {
            console.log('Checking closed caption');
            asset.mediainfo.map((info) => {
              console.log(JSON.parse(info));
            });
          }
          return ['OK'];
        }
      }*/
    ],
    manual: [
      {
        id: 'MANUAL_SUBTITLE_SYNC_25_CHECK',
        playerCommand: 'SEEK_TO_25',
        question: 'Check subtitle sync 25% into the asset',
        options: [
          { answer: 'In sync', value: 'IN_SYNC', verdict: 'PASSED' },
          { answer: 'Out of sync', value: 'OUT_OF_SYNC', verdict: 'FAILED' }
        ]
      },
      {
        id: 'MANUAL_SUBTITLE_50_CHECK',
        playerCommand: 'SEEK_TO_50',
        question: 'Check subtitle sync 50% into the asset',
        options: [
          { answer: 'In sync', value: 'IN_SYNC', verdict: 'PASSED' },
          { answer: 'Out of sync', value: 'OUT_OF_SYNC', verdict: 'FAILED' }
        ]
      },
      {
        id: 'MANUAL_SUBTITLE_75_CHECK',
        playerCommand: 'SEEK_TO_75',
        question: 'Check subtitle sync 75% into the asset',
        options: [
          { answer: 'In sync', value: 'IN_SYNC', verdict: 'PASSED' },
          { answer: 'Out of sync', value: 'OUT_OF_SYNC', verdict: 'FAILED' }
        ]
      }
    ]
  }
};

//-- Helper methods for the QC checks

/*function findPropertyAboveValue(mediainfo: string[] | undefined, propName: string) {
  if (!mediainfo) return undefined;
  const property = mediainfo.find((info) => info.includes(propName));
  if (!property) return undefined;
  const obj = JSON.parse(property || '');

  // find property in object indepent of case
  const res = Object.keys(obj).find((key) => key.toLowerCase() === propName.toLowerCase());
  if (!res) return undefined;
  return obj[res];
}
*/
export function extractOptionValues(
  check: QCCheck,
  verdict: 'PASSED' | 'FAILED' | 'WARNING'
): string[] {
  return (
    check.options?.filter((option) => option.verdict === verdict).map((option) => option.value) ||
    []
  );
}

function getMediaInfo(
  asset?: Asset
): MediaInfoVideo | MediaInfoAudio | MediaInfoSubtitle | undefined {
  if (!asset) return undefined;
  if (asset.type === 'VIDEO') {
    return asset.mediainfo?.map((info) => JSON.parse(info)) as MediaInfoVideo;
  } else if (asset.type === 'AUDIO') {
    return asset.mediainfo?.map((info) => JSON.parse(info)) as MediaInfoAudio;
  } else if (asset.type === 'SUBTITLE') {
    return asset.mediainfo?.map((info) => JSON.parse(info)) as MediaInfoSubtitle;
  }
  return undefined;
}

// --- VIDEO CHECKS Functions
const AcceptedVideoQualities = ['4K', '1440p', '1080p', '720p'];
/**
 * Evaluates the video resolution height and returns a common quality label
 * (e.g. "4K", "1440p", "1080p", etc.). Falls back to "Unknown" if it cannot
 * determine a valid height.
 */
function GetVideoQuality(mediaInfo: MediaInfoVideo): string | undefined {
  // The video info is the second element in the array
  const videoInfo = mediaInfo[1];
  const height = parseInt(videoInfo.Height, 10);

  if (isNaN(height) || height <= 0) {
    return undefined;
  }

  if (height >= 2160) {
    return '4K';
  } else if (height >= 1440) {
    return '1440p';
  } else if (height >= 1080) {
    return '1080p';
  } else if (height >= 720) {
    return '720p';
  } else if (height >= 480) {
    return '480p';
  }

  return 'SD or lower';
}

const AcceptedAspectRatios = ['16:9', '21:9'];
/**
 * Attempts to calculate the aspect ratio as a human-readable string.
 *  1. If DisplayAspectRatio is provided, use that.
 *  2. Otherwise, compute ratio from Width / Height.
 *  3. Convert ratio to a standard label (e.g., "16:9") if it matches closely,
 *     otherwise return undefined.
 */
function GetAspectRatio(mediaInfo: MediaInfoVideo): string | undefined {
  const videoInfo = mediaInfo[1];

  // Try using explicit DisplayAspectRatio first
  let ratio = parseFloat(videoInfo.DisplayAspectRatio);

  // If not valid, attempt to derive from Width and Height
  if (isNaN(ratio) && videoInfo.Width && videoInfo.Height) {
    const width = parseInt(videoInfo.Width, 10);
    const height = parseInt(videoInfo.Height, 10);
    if (!isNaN(width) && !isNaN(height) && height !== 0) {
      ratio = width / height;
    }
  }

  // If still invalid, return undefined
  if (isNaN(ratio) || ratio <= 0) {
    return undefined;
  }

  // Common aspect ratios to compare against
  const aspectRatios: { label: string; value: number }[] = [
    { label: '4:3', value: 4 / 3 },
    { label: '16:9', value: 16 / 9 },
    { label: '21:9', value: 21 / 9 }
  ];

  // Tolerance for matching a known ratio
  const tolerance = 0.05;

  // Check if the ratio matches any standard ratio closely
  for (const ar of aspectRatios) {
    if (Math.abs(ratio - ar.value) < tolerance) {
      return ar.label;
    }
  }

  // If no standard ratio matched, return undefined
  return undefined;
}
const BASE_RECOMMENDED_BITRATE_MAP: Record<string, number> = {
  '4K': 20000000, // ~20 Mbps baseline at 30 fps
  '1440p': 10000000, // ~10 Mbps baseline at 30 fps
  '1080p': 5000000, // ~5 Mbps baseline at 30 fps
  '720p': 2500000, // ~2.5 Mbps baseline at 30 fps
  '480p': 1000000, // ~1 Mbps baseline at 30 fps
  SD: 700000, // ~700 Kbps baseline at 30 fps
  Unknown: 500000 // ~500 Kbps fallback
};
/**
 * Calculates the average video bit rate, in bits per second, by using
 * (StreamSize * 8) / Duration. Falls back to the "BitRate" field if needed.
 * Returns a string like "106098824" or undefined if it cannot be determined.
 */
function GetVideoBitRate(mediaInfo: MediaInfoVideo): number | undefined {
  const videoInfo = mediaInfo[1];

  const streamSizeBytes = parseInt(videoInfo.StreamSize, 10);
  const durationSec = parseFloat(videoInfo.Duration);

  // If we have enough data to compute the bit rate, do that
  if (!isNaN(streamSizeBytes) && streamSizeBytes > 0 && !isNaN(durationSec) && durationSec > 0) {
    const calculatedBitRate = (streamSizeBytes * 8) / durationSec; // bits per second
    return Math.round(calculatedBitRate);
  }

  // Otherwise, fall back to the BitRate field if present
  if (videoInfo.BitRate && !isNaN(parseInt(videoInfo.BitRate, 10))) {
    return parseInt(videoInfo.BitRate, 10);
  }

  return undefined;
}
/**
 * Determines whether the video's bitrate is "good" or not,
 * based on the resolution + framerate vs. the actual bitrate.
 *
 * Returns:
 *   - true, if the bitrate is above our recommended threshold
 *   - false, otherwise
 */
function IsVideoBitrateGood(mediaInfo: MediaInfoVideo): boolean {
  // Reuse the previously defined helpers
  const quality = GetVideoQuality(mediaInfo); // e.g., "1080p"
  const rawFrameRate = parseFloat(mediaInfo[1].FrameRate); // e.g., 23.976
  const actualBitRate = GetVideoBitRate(mediaInfo); // e.g., "106098824" bps

  // If we are missing any critical info, default to "not good"
  if (!actualBitRate || !quality || isNaN(rawFrameRate) || isNaN(actualBitRate)) {
    return false;
  }

  // Find a baseline recommended bitrate (for ~30 fps) based on the quality
  const baseRecommended =
    BASE_RECOMMENDED_BITRATE_MAP[quality] ?? BASE_RECOMMENDED_BITRATE_MAP['Unknown'];

  // Adjust recommended bitrate by the ratio of (actualFps / 30)
  // Example: If we have 60fps, we might require roughly twice the baseline
  const frameRateFactor = rawFrameRate / 30;
  const recommendedBitrate = baseRecommended * frameRateFactor;

  // Compare actual to recommended
  return actualBitRate >= recommendedBitrate;
}

//---- Audio Checks Functions
function doesAudioDurationFitVideo(
  videoMediaInfo: MediaInfoVideo,
  audioMediaInfo: MediaInfoAudio,
  options?: {
    maxShortfallSeconds?: number; // e.g. 2 seconds
    maxShortfallPercent?: number; // e.g. 0.05 (5%)
    maxExcessSeconds?: number; // e.g. 2 seconds
  }
): boolean {
  // Reasonable defaults; adjust as needed
  const {
    maxShortfallSeconds = 2,
    maxShortfallPercent = 0.05,
    maxExcessSeconds = 2
  } = options || {};

  const videoTrack = videoMediaInfo[1];
  const audioTrack = audioMediaInfo[1];

  // Parse durations (in seconds)
  const videoDuration = parseFloat(videoTrack.Duration);
  const audioDuration = parseFloat(audioTrack.Duration);

  // If either duration is missing or invalid, fail
  if (isNaN(videoDuration) || isNaN(audioDuration)) {
    console.debug('Invalid duration(s)', videoDuration, audioDuration);
    return false;
  }

  // Calculate difference: how many seconds the audio is shorter (if positive)
  // or longer (if negative) than the video.
  const difference = videoDuration - audioDuration;

  // 1) If audio is *longer* than video by more than allowed
  if (difference < 0 && Math.abs(difference) > maxExcessSeconds) {
    // For instance, audio is 5 seconds longer than video and maxExcessSeconds is 2 => fail
    console.debug(
      `Audio is too long.\n Video duration: ${videoDuration}\n Audio duration: ${audioDuration}`
    );
    return false;
  }

  // 2) If audio is *shorter* than video by more than some threshold
  if (difference > 0) {
    // Check absolute time difference
    if (difference > maxShortfallSeconds) {
      // If difference is bigger than allowed shortfall, also fail
      console.debug(
        `Audio is too short.\n Video duration: ${videoDuration}\n Audio duration: ${audioDuration}`
      );
      return false;
    }
    // Check relative difference, e.g., 5% of video duration
    const shortfallPercent = difference / videoDuration;
    if (shortfallPercent > maxShortfallPercent) {
      // If difference in percentage is bigger than allowed shortfall percentage, also fail
      console.debug(
        `Audio is too short in percentage.\n Video duration: ${videoDuration}\n Audio duration: ${audioDuration}`
      );
      return false;
    }
  }

  // If neither condition is violated, we consider audio "fits"
  return true;
}
