import { createSlice } from '@reduxjs/toolkit';
import { Asset, CatalogIdTypeDistributorMapping, RepositoryItemDetails } from './types';
import { AssetQualityControlStep } from '../../components/repository-quality-control/AssetQualityControlSteps';

const initialState: {
  repositoryItem?: RepositoryItemDetails;
  selectedRows: Set<number>;

  // Quality Control Page
  baselineVideoAsset?: Asset | undefined;
  // Active Asset can be a VIDEO or a SIDECAR file (represents the chosen asset in tree view)
  activeAsset?: Asset | undefined;

  steps?: AssetQualityControlStep[];
  activeStep?: AssetQualityControlStep;
} = {
  repositoryItem: undefined,
  selectedRows: new Set()
};
export const repositoryItemSlice = createSlice({
  name: 'repositoryItemSlice',
  initialState,
  reducers: {
    setRepositoryItem: (state, action) => {
      state.repositoryItem = action.payload;

      // If the repository starts with any of the options, set the catalogIdType to 'MPM'
      if (
        state.repositoryItem &&
        !state.repositoryItem.catalogIdType &&
        CatalogIdTypeDistributorMapping.MPM.some((option) =>
          state.repositoryItem?.repository?.startsWith(option)
        )
      ) {
        state.repositoryItem.catalogIdType = 'MPM';
      }
    },
    updateRepositoryItemExternalIds: (state, action) => {
      if (state.repositoryItem) {
        state.repositoryItem.eidrId = action.payload.eidrId;
        state.repositoryItem.titleCatalogId = action.payload.catalogId;
      }
    },
    addRepositoryItemHistoryMessage: (state, action) => {
      if (state.repositoryItem) {
        state.repositoryItem.history.push(action.payload);
      }
    },

    /*Quality Control*/
    updateRepositoryItemAssets: (state, action) => {
      if (state.repositoryItem) {
        state.repositoryItem.assets = action.payload;
      }
    },
    setBaselineVideoAsset: (state, action) => {
      state.baselineVideoAsset = action.payload;
    },
    setActiveAsset: (state, action) => {
      state.activeAsset = action.payload;
    },
    setSteps: (state, action) => {
      state.steps = action.payload;
    },
    setActiveStep: (state, action) => {
      state.activeStep = action.payload;
    }
  }
});

export const {
  setRepositoryItem,
  updateRepositoryItemExternalIds,
  addRepositoryItemHistoryMessage,
  updateRepositoryItemAssets,
  setBaselineVideoAsset,
  setActiveAsset,
  setSteps,
  setActiveStep
} = repositoryItemSlice.actions;

export default repositoryItemSlice;
