import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

// -- External components & icons --
import HomeIcon from '../../assets/icons/home.svg?react';
import Breadcrumbs, { BreadcrumbsItem } from '../../components/ui/Breadcrumbs';
import Skeleton from '../../components/ui/Skeleton';

// -- Internal components --
import AssetQualityControlSteps, {
  AssetQualityControlStep,
  getChecksForAssetType,
  extractStepsFromQcChecks
} from '../../components/repository-quality-control/AssetQualityControlSteps';
import AssessmentForm from '../../components/repository-quality-control/AssessmentForm';
import StepValidationForm from '../../components/repository-quality-control/StepValidationFormItem';

// -- Api & Redux store --
import {
  addAssetQcReport,
  getRepositoryItem,
  getRepositoryItemAssets
} from '../../features/repository/repositoryApi';
import {
  useGeneratePreviewTokenMutation,
  useGetProcessMutation,
  usePreviewGenerateMutation
} from '../../features/repository/castlabsRepositoryApi';

import {
  setActiveAsset,
  setActiveStep,
  setRepositoryItem,
  setSteps,
  updateRepositoryItemAssets
} from '../../features/repository/repositoryItemSlice';

// -- Types & utils --
import {
  Asset,
  AssetQCReportInput,
  QCReportResolution,
  RepositoryItemDetails
} from '../../features/repository/types';
import VideoQcConflictModal from '../../components/repository-quality-control/VideoQcConflictModal';
import { RootState } from '../../app/store';
import { getOptions } from '../../common/utils';
import RepositoryItemAssetsTreeView from '../../components/repository-quality-control/RepositoryItemAssetsTreeView';

import MezzaninePlayer from '../../components/asset/MezzaninePlayer';

interface PreviewTokens {
  dashManifestUrl: string;
  drmTodayToken: string;
}

const breadcrumbItems: BreadcrumbsItem[] = [
  {
    link: '/',
    icon: <HomeIcon />
  },
  {
    title: 'Repository',
    link: '/repository'
  }
];

/**
 * The main Quality Control page component.
 */
export default function QualityControlPage() {
  const { repositoryItemId } = useParams();
  const [nextAsset, setNextAsset] = useState<Asset | undefined>();
  const { steps, repositoryItem, baselineVideoAsset, activeAsset, activeStep } = useSelector(
    (state: RootState) => state.repositoryItem
  );
  const dispatch = useDispatch();

  // -- Submitted QC reports --
  const [qcReports, setQcReports] = useState<
    Record<string, { comment?: string; formValues: Record<string, string[] | null | undefined> }>
  >(Object.create(null));
  // -- Loading & UI state --
  const [isLoading, setIsLoading] = useState(true);

  const [previewTokens, setPreviewTokens] = useState<PreviewTokens | null>(null);

  const acceptedSidecarFiles = repositoryItem?.assets.filter(
    (asset) => asset.type !== 'VIDEO' && asset.status === 'ACCEPTED'
  );

  // -- QC answers data --
  const [assetComment, setAssetComment] = useState<string | undefined>();
  const [assetQcReport, setAssetQcReport] = useState<Record<string, string[] | null | undefined>>(
    {}
  );

  // -- QC conflict modal --
  const [isConflictAction, setIsConflictAction] = useState<
    'TO_CONFORM' | 'TO_DELETE' | undefined
  >();

  // -- API mutations --
  const [previewGenerate] = usePreviewGenerateMutation();
  const [generatePreviewToken] = useGeneratePreviewTokenMutation();
  const [processQuery] = useGetProcessMutation();

  /**
   * Fetches the repository item for the provided `repositoryItemId` and updates component state.
   */
  async function fetchRepositoryItem(id: string) {
    try {
      setIsLoading(true);
      const repoItem = await getRepositoryItem(id);

      dispatch(setRepositoryItem(repoItem));

      // Prepare and load UI data
      setIsLoading(false);
      await prepareVideoPlayer(repoItem);
    } catch (error) {
      console.error('Error fetching repository item:', error);
      setIsLoading(false);
    }
  }

  /**
   * Generates a preview of the video in CastLabs, polls for process success,
   * and once complete, fetches a preview token for the player.
   */
  async function prepareVideoPlayer(repoItem: RepositoryItemDetails) {
    const { data: generatePreviewResponse } = await previewGenerate({
      folder_id: repoItem.folderLocation
    });
    if (!generatePreviewResponse) return;

    const process = await fetchSuccessProcess(generatePreviewResponse.id);
    if (!process) return;

    try {
      const previewGenerateData = JSON.parse(process.data);
      const tokenResponse = await generatePreviewToken({ asset_id: previewGenerateData.asset_id });
      if (tokenResponse.data) {
        setPreviewTokens({
          dashManifestUrl: tokenResponse.data.dash_url,
          drmTodayToken: tokenResponse.data.drmtoday_token
        });
      }
    } catch (error) {
      console.error('Error generating preview token', error);
    }
  }

  /**
   * Polls for a process to reach 'SUCCESS' state and returns the process data.
   * Retries up to `maxRetries` times with a delay of `retryDelay` between attempts.
   */
  async function fetchSuccessProcess(
    id: string,
    maxRetries: number = 100,
    retryDelay: number = 5000
  ) {
    async function sleep(ms: number) {
      return new Promise((resolve) => setTimeout(resolve, ms));
    }

    for (let attempt = 1; attempt <= maxRetries; attempt++) {
      try {
        const { data: process } = await processQuery({ id });
        if (!process) {
          console.warn(`No process data returned on attempt ${attempt}.`);
        } else {
          switch (process.state) {
            case 'SUCCESS':
              return process;
            case 'IN_PROGRESS':
              if (attempt < maxRetries) {
                await sleep(retryDelay);
              }
              break;
            case 'FAILED':
              return null;
            default:
              console.warn(`Unknown process state: ${process.state}`);
          }
        }
      } catch (error) {
        console.error(`processQuery failed on attempt ${attempt}:`, error);
        throw error;
      }
    }
    throw new Error(`Process did not reach 'SUCCESS' after ${maxRetries} attempts.`);
  }

  /**
   * Called when user completes or submits the QC report (e.g., clicking 'Submit' on the form).
   * For now, this is a placeholder to show where you'd handle final data submission.
   */
  async function handleQcReportSubmission(
    action: 'ACCEPTED' | 'TO_CONFORM' | 'TO_DELETE',
    force: boolean = false
  ) {
    if (!steps || !activeAsset) return;

    // If the asset is a video and has sidecar files, show a conflict modal warning user that the sidecar files will be deleted
    if (
      (action === 'TO_DELETE' || action === 'TO_CONFORM') &&
      activeAsset?.type === 'VIDEO' &&
      acceptedSidecarFiles &&
      acceptedSidecarFiles.length &&
      !force
    ) {
      setIsConflictAction(action);
      return;
    }

    // Submit QC data somewhere...
    console.log('QC Report submitted:', assetQcReport);

    // Submit QC Report

    try {
      if (
        await addAssetQcReport(
          activeAsset._id,
          createAssetQcReportInputRequest(steps, action, assetComment, assetQcReport),
          force
        )
      ) {
        // Store the QC report in page state
        setQcReports((prev) => ({
          ...prev,
          [activeAsset._id]: {
            assetComment,
            formValues: assetQcReport
          }
        }));

        // Update the list of assets
        dispatch(updateRepositoryItemAssets(await getRepositoryItemAssets(repositoryItemId!)));

        // Move to the next asset
        if (nextAsset) dispatch(setActiveAsset(nextAsset));
      }
    } catch (e) {
      console.error('Error submitting QC report:', e);
    }
  }

  // Fetch data when `repositoryItemId` changes
  useEffect(() => {
    if (repositoryItemId) fetchRepositoryItem(repositoryItemId);
  }, [repositoryItemId]);

  useEffect(() => {
    if (activeAsset) {
      const qcChecks = getChecksForAssetType(activeAsset.type);
      const _steps = extractStepsFromQcChecks(qcChecks);
      dispatch(setSteps(_steps));
      const firstManualStep = _steps.find((step) => step.type === 'manual');
      dispatch(setActiveStep(firstManualStep));

      // Prepopulate with existing QC report if available or execute auto checks
      if (qcReports[activeAsset._id]) {
        setAssetComment(qcReports[activeAsset._id].comment);
        setAssetQcReport(qcReports[activeAsset._id].formValues);
      } else {
        const autoChecksResponse =
          qcChecks.auto?.reduce(
            (result, step) => {
              result[step.id] ||= step.check(baselineVideoAsset, activeAsset);
              return result;
            },
            {} as Record<string, string[] | null | undefined>
          ) || {};

        setAssetQcReport(autoChecksResponse);
        setAssetComment('');
      }
    }
  }, [activeAsset]);

  return (
    <>
      <Helmet>
        <title>Above Media | Repository</title>
      </Helmet>
      <Breadcrumbs items={breadcrumbItems} />

      <div className="container mx-auto mb-8 bg-white border-2 border-gray-200 rounded-xl">
        <div className="p-5 border-b border-gray-100">
          <div className="font-semibold text-lg text-gray-900">Quality Control Page</div>
        </div>

        {isLoading && <Skeleton />}

        {!isLoading && (
          <div className="relative mx-12">
            {/* TreeView (left sidebar) */}
            <RepositoryItemAssetsTreeView setNextAsset={setNextAsset} />
            {/*Main content area*/}
            <div className="grid grid-cols-7 gap-8 mt-12">
              {/* QC Steps*/}
              <div className="col-span-2 flex flex-row ml-8 relative">
                <AssetQualityControlSteps
                  className="sticky pt-8 top-0"
                  assetQcReport={assetQcReport}
                />
              </div>
              {/* Player & QC Forms*/}
              <div className={'col-span-5'}>
                <div className="p-8 bg-gray-50 rounded-2xl">
                  <MezzaninePlayer
                    className="mb-8"
                    dashManifestUrl={previewTokens?.dashManifestUrl ?? ''}
                    drmTodayToken={previewTokens?.drmTodayToken ?? ''}
                    rounded
                    activeVideo={activeAsset?.type === 'VIDEO' ? activeAsset : baselineVideoAsset}
                    activeAudio={
                      activeAsset?.type === 'AUDIO'
                        ? activeAsset
                        : activeAsset?.type === 'VIDEO'
                          ? activeAsset
                          : baselineVideoAsset
                    }
                    activeSubtitle={
                      ['SUBTITLE', 'CLOSED_CAPTION'].includes(activeAsset?.type ?? '')
                        ? activeAsset
                        : undefined
                    }
                  />
                  {/*Step Validation Form*/}
                  {activeStep && (
                    <StepValidationForm
                      assetQcReport={assetQcReport}
                      setAssetQcReport={(value) => {
                        setAssetQcReport((prev) => ({ ...prev, ...value }));
                      }}
                    />
                  )}
                </div>
                <AssessmentForm
                  comment={assetComment}
                  setComment={setAssetComment}
                  canApprove={canApproveAsset(steps ?? [], assetQcReport)}
                  onSubmit={handleQcReportSubmission}
                />
                {/*Final QC approval form*/}

                {isConflictAction && (
                  <VideoQcConflictModal
                    onCancel={() => setIsConflictAction(undefined)}
                    onConfirm={() => handleQcReportSubmission(isConflictAction, true)}
                    acceptedSidecarFiles={acceptedSidecarFiles}
                  />
                )}
              </div>
            </div>
          </div>
        )}
      </div>
    </>
  );
}

//---> Helper functions

function createAssetQcReportInputRequest(
  steps: AssetQualityControlStep[],
  action: QCReportResolution,
  assetComment: string = '',
  assetQcReport: Record<string, string[] | null | undefined>
): AssetQCReportInput {
  return {
    questions: steps.map((step) => ({
      question: step.title,
      answer: extractAnswer(step.form.options, assetQcReport[step.id]),
      verdict: extractVerdict(
        step.form.failedOptionKeys,
        step.form.warningOptionKeys,
        assetQcReport[step.id]
      )
    })),
    generalObservation: assetComment,
    resolution: action
  };
  //----------------------------
  function extractVerdict(
    badOptionKeys: string[],
    warningOptionKeys: string[],
    answer: string[] | null | undefined
  ): QCReportResolution {
    if (!answer) return 'WARNING';

    if (answer.some((option) => badOptionKeys.includes(option))) {
      return 'FAILED';
    }
    if (answer.some((option) => warningOptionKeys.includes(option))) {
      return 'WARNING';
    }

    return 'PASSED';
  }
  function extractAnswer(
    options: { [key: string]: string },
    answer: string[] | null | undefined
  ): string {
    if (!answer || answer.length === 0) return '';

    return getOptions(options)
      .filter((option) => answer.includes(option.value))
      .map((option) => option.label)
      .join(', ');
  }
}

/**
 * Determines if an asset can be approved based on provided QC steps and user-selected answers.
 * Returns `true` if all steps are completed, no bad options are selected, otherwise `false`.
 */
function canApproveAsset(
  steps: AssetQualityControlStep[],
  assetQcReportAnswers: Record<string, string[] | null | undefined>
): boolean {
  if (!steps.length) return false;

  const passedSteps = steps.filter((step) => {
    const answers = assetQcReportAnswers[step.id];
    if (!answers || answers.length === 0) return false;

    const hasFailedOption = answers.some(
      (option) =>
        step.form.failedOptionKeys.includes(option) || step.form.warningOptionKeys.includes(option)
    );
    return !hasFailedOption;
  });

  return passedSteps.length === steps.length;
}
