import React, { useState, useEffect } from 'react';
import { AbovePlayer } from './AbovePlayer';
import { clpp } from '@castlabs/prestoplay';
import { Asset } from '../../features/repository/types';

export enum PlayerCommand {
  GoTo25Percent = 'GO_TO_25%',
  GoTo50Percent = 'GO_TO_50%',
  GoTo75Percent = 'GO_TO_75%'
}

interface MezzaninePlayerProps {
  className?: string;
  dashManifestUrl: string;
  drmTodayToken: string;
  rounded?: boolean;
  activeVideo?: Asset;
  activeAudio?: Asset;
  activeSubtitle?: Asset;
  command?: PlayerCommand;
}

interface ManifestRepresentation {
  'adaptation set': string;
  'representation id': string;
  'source file name': string;
  'source folder id': string;
  'source track_id': number;
}

interface ManifestMetadata {
  videos: ManifestRepresentation[];
  audios: ManifestRepresentation[];
  subtitles: ManifestRepresentation[];
}

function parseManifestMetadata(data: Record<string, ManifestRepresentation>): ManifestMetadata {
  const videos: ManifestRepresentation[] = [];
  const audios: ManifestRepresentation[] = [];
  const subtitles: ManifestRepresentation[] = [];

  Object.keys(data).forEach((key) => {
    const rendition = data[key];
    if (key.startsWith('videos_')) {
      videos.push(rendition);
    } else if (key.startsWith('audios_')) {
      audios.push(rendition);
    } else if (key.startsWith('subs_')) {
      subtitles.push(rendition);
    }
  });

  return { videos, audios, subtitles };
}

const MezzaninePlayer: React.FC<MezzaninePlayerProps> = ({
  className,
  dashManifestUrl,
  drmTodayToken,
  activeVideo,
  activeAudio,
  activeSubtitle,
  command,
  rounded = true
}) => {
  const [manifestMetadata, setManifestMetadata] = useState<ManifestMetadata | null>(null);
  const [clppPlayer, setClppPlayer] = useState<clpp.Player | null>(null);

  useEffect(() => {
    if (!dashManifestUrl) return;

    const metadataUrl = dashManifestUrl.replace('Manifest.mpd', 'metadata.json');
    fetch(metadataUrl)
      .then((response) => response.json())
      .then((data) => {
        const parsedMetadata = parseManifestMetadata(data);
        setManifestMetadata(parsedMetadata);
      })
      .catch((error) => console.error('Error fetching manifest metadata:', error));
  }, [dashManifestUrl]);

  const findRepresentation = (
    assetLocation: string,
    type: keyof ManifestMetadata
  ): ManifestRepresentation | undefined => {
    const representations = manifestMetadata?.[type] || [];
    return representations.find(
      (r) => `${r['source folder id']}${r['source file name']}` === assetLocation
    );
  };

  const playAsset = (asset: Asset | undefined, type: keyof ManifestMetadata) => {
    if (!clppPlayer || !manifestMetadata) return;

    const manager = clppPlayer.getTrackManager()!;

    const findRendition = {
      videos: manager.findVideoRendition,
      audios: manager.findAudioRendition,
      subtitles: manager.findTextRendition
    }[type];
    const setRendition = {
      videos: manager.setVideoRendition,
      audios: manager.setAudioRendition,
      subtitles: manager.setTextRendition
    }[type];

    if (asset) {
      const representation = findRepresentation(asset.location, type);
      const rendition = findRendition.call(manager, {
        originalId: representation ? representation['representation id'] : ''
      });
      if (rendition) {
        setRendition.call(manager, rendition, true);
      }
    } else {
      // the clpp typing is incorrect here and null can be used to unselect text tracks
      // @ts-expect-error - null is not assignable to type 'Rendition'
      setRendition.call(manager, null, true);
    }
  };

  useEffect(() => {
    playAsset(activeVideo, 'videos');
    playAsset(activeAudio, 'audios');
    playAsset(activeSubtitle, 'subtitles');
  }, [clppPlayer]);

  useEffect(() => {
    playAsset(activeVideo, 'videos');
  }, [activeVideo]);

  useEffect(() => {
    playAsset(activeAudio, 'audios');
  }, [activeAudio]);

  useEffect(() => {
    playAsset(activeSubtitle, 'subtitles');
  }, [activeSubtitle]);

  useEffect(() => {
    if (!command || !clppPlayer) return;

    const pointInTime = {
      [PlayerCommand.GoTo25Percent]: 0.25,
      [PlayerCommand.GoTo50Percent]: 0.5,
      [PlayerCommand.GoTo75Percent]: 0.75
    }[command];
    if (!pointInTime) return;

    clppPlayer.seek(pointInTime * clppPlayer.getDuration());
  }, [command]);

  return (
    <AbovePlayer
      className={className}
      dashManifestUrl={dashManifestUrl}
      drmTodayToken={drmTodayToken}
      rounded={rounded}
      onPlayerLoaded={(player) => setClppPlayer(player)}
    />
  );
};

export default MezzaninePlayer;
