import React, { useState, useEffect } from 'react';
import { AbovePlayer } from './AbovePlayer';
import { clpp } from '@castlabs/prestoplay';
import CheckIcon from '../../assets/icons/check.svg?react';
import styles from './AbovePlayer.module.css';
import { clsx } from 'clsx';

interface EncodePlayerProps {
  className?: string;
  dashManifestUrl: string;
  drmTodayToken: string;
  initialTrackFileName?: string;
  rounded?: boolean;
}

const EncodePlayer: React.FC<EncodePlayerProps> = ({
  className,
  dashManifestUrl,
  drmTodayToken
}) => {
  const [videoRenditions, setVideoRenditions] = useState<clpp.Rendition[]>([]);
  const [audioRenditions, setAudioRenditions] = useState<clpp.Rendition[]>([]);
  const [subtitleRenditions, setSubtitleRenditions] = useState<clpp.Rendition[]>([]);
  const [activeVideoId, setActiveVideoId] = useState<string | null>(null);
  const [activeAudioId, setActiveAudioId] = useState<string | null>(null);
  const [activeSubtitleId, setActiveSubtitleId] = useState<string | null>(null);

  const [clppPlayer, setClppPlayer] = useState<clpp.Player | null>(null);

  const handlePlayerLoaded = (player: clpp.Player) => {
    setClppPlayer(player);
    player.addEventListener(clpp.events.TRACKS_ADDED, () => {
      const flattenRenditions = (tracks: clpp.Track[]): clpp.Rendition[] => {
        return tracks.reduce((acc: clpp.Rendition[], track) => acc.concat(track.renditions), []);
      };

      const manager = player.getTrackManager()!;
      setVideoRenditions(flattenRenditions(manager.getVideoTracks()));
      setAudioRenditions(flattenRenditions(manager.getAudioTracks()));
      setSubtitleRenditions(flattenRenditions(manager.getTextTracks()));
      setActiveVideoId(manager.getVideoRendition()?.originalId || null);
      setActiveAudioId(manager.getAudioRendition()?.originalId || null);
      setActiveSubtitleId(manager.getTextRendition()?.originalId || null);
    });
  };

  useEffect(() => {
    const rendition = videoRenditions.find((r) => r.originalId === activeVideoId);
    if (rendition) {
      clppPlayer?.getTrackManager()?.setVideoRendition(rendition, true);
    }
  }, [activeVideoId]);

  useEffect(() => {
    const rendition = audioRenditions.find((r) => r.originalId === activeAudioId);
    if (rendition) {
      clppPlayer?.getTrackManager()?.setAudioRendition(rendition, true);
    }
  }, [activeAudioId]);

  useEffect(() => {
    const rendition = subtitleRenditions.find((r) => r.originalId === activeSubtitleId);
    if (rendition) {
      clppPlayer?.getTrackManager()?.setTextRendition(rendition, true);
    }
  }, [activeSubtitleId]);

  return (
    <div className={clsx(className, 'relative')}>
      <AbovePlayer
        dashManifestUrl={dashManifestUrl}
        drmTodayToken={drmTodayToken}
        rounded={false}
        onPlayerLoaded={handlePlayerLoaded}
      />
      <div className={styles.tracksContainer}>
        {videoRenditions.length > 0 && (
          <div className="my-2">
            <div className="font-medium ml-3">Video:</div>
            {videoRenditions.map((rendition) => (
              <div
                key={rendition.originalId}
                className={styles.track}
                onClick={() => setActiveVideoId(rendition.originalId)}>
                <div className={'w-5 mr-2'}>
                  {rendition.originalId === activeVideoId && <CheckIcon className="stroke-white" />}
                </div>
                {rendition.height}p
              </div>
            ))}
          </div>
        )}
        {audioRenditions.length > 0 && (
          <div className={'my-2'}>
            <div className={'font-medium ml-3'}>Audio:</div>
            {audioRenditions.map((rendition) => (
              <div
                key={rendition.originalId}
                className={styles.track}
                onClick={() => setActiveAudioId(rendition.originalId)}>
                <div className={'w-5 mr-2'}>
                  {rendition.originalId === activeAudioId && <CheckIcon className="stroke-white" />}
                </div>
                {rendition.track.language || `track ${rendition.originalId}`} {rendition.track.kind}
              </div>
            ))}
          </div>
        )}
        {subtitleRenditions.length > 0 && (
          <div className={'my-2'}>
            <div className={'font-medium ml-3'}>Subtitle:</div>
            {subtitleRenditions.map((rendition) => (
              <div
                key={rendition.originalId}
                className={styles.track}
                onClick={() => setActiveSubtitleId(rendition.originalId)}>
                <div className={'w-5 mr-2'}>
                  {rendition.originalId === activeSubtitleId && (
                    <CheckIcon className="stroke-white" />
                  )}
                </div>
                {rendition.track.language || `track ${rendition.originalId}`}
              </div>
            ))}
          </div>
        )}
      </div>
    </div>
  );
};

export default EncodePlayer;
