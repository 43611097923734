import Modal from '../ui/Modal';
import Button from '../ui/Button';
import CloseIcon from '../../assets/icons/close.svg?react';
import { RepositoryItem } from '../../features/repository/types';
import React, { ReactElement, useEffect, useState } from 'react';
import { iconsMap } from '../../pages/repository/MetadataRichSearchPage';
import ArrowRightIcon from '../../assets/icons/arrow-right.svg?react';
import { useSelector } from 'react-redux';
import { RootState } from '../../app/store';
import clsx from 'clsx';
type FormValues = {
  catalogId?: string | undefined;
  catalogIdType?: string | undefined;
  eidrId?: string | undefined;
};
function ConfirmUpdateRepositoryItemExternalIdsModal({
  itemsWithSameIds,
  formValues,
  onSubmit
}: {
  itemsWithSameIds?: {
    itemsWithSameCatalogId?: RepositoryItem[];
    itemsWithSameEidr?: RepositoryItem[];
  };
  formValues?: FormValues;
  onSubmit?: (action: 'CANCEL' | 'UPDATE_ONE' | 'UPDATE_ALL') => Promise<void>;
}) {
  const { repositoryItem } = useSelector((state: RootState) => state.repositoryItem);
  const [loading, setLoading] = useState(false);
  const clickHandler = async (action: 'CANCEL' | 'UPDATE_ONE' | 'UPDATE_ALL') => {
    if (onSubmit) {
      setLoading(true);
      await onSubmit(action);
      setLoading(false);
    }
  };
  const [conflictItems, setConflictItems] = useState<RepositoryItem[] | null>(null);

  useEffect(() => {
    const temp = (itemsWithSameIds?.itemsWithSameCatalogId || []).concat(
      itemsWithSameIds?.itemsWithSameEidr || []
    );
    // filter out the repeated items
    const uniqueItems = temp.filter(
      (item, index, self) =>
        index ===
        self.findIndex(
          (t) =>
            t.titleCatalogId === item.titleCatalogId &&
            t.catalogIdType === item.catalogIdType &&
            t.eidrId === item.eidrId
        )
    );
    setConflictItems(uniqueItems);
  }, [itemsWithSameIds]);

  if (!conflictItems || conflictItems.length == 0) return null;

  function renderContentTitleColumn(title: RepositoryItem): ReactElement {
    function renderContentTitle(title: RepositoryItem): ReactElement {
      const isEpisode = title.itemType === 'EPISODE';
      const getSearchUrl = (title: RepositoryItem, cfg?: { seasonNumber: boolean }) => {
        const p = { ...{ seasonNumber: true }, ...cfg };
        return (
          `/repository?distributor=${title.repository.split('__')[0]}&repository=${title.repository}&parentId=${title.parentId}` +
          (p.seasonNumber ? `&seasonNumber=${title.seasonNumber}` : '')
        );
      };

      return (
        <div className="flex flex-col ">
          <div className="flex flex-row items-center gap-2 text-gray">
            {isEpisode &&
              repositoryItem &&
              title &&
              repositoryItem.parentId &&
              title.parentId &&
              repositoryItem.parentId === title.parentId && (
                <>
                  <a
                    href={getSearchUrl(title, { seasonNumber: false })}
                    target={'_blank'}
                    rel="noreferrer">
                    <span className="text-grey p-0 justify-center items-center">Same Series</span>
                  </a>
                  <ArrowRightIcon className="w-4 h-4 mt-0.5 stroke-gray-400" />
                </>
              )}
            {isEpisode &&
              repositoryItem &&
              title &&
              repositoryItem.parentId &&
              title.parentId &&
              repositoryItem.parentId !== title.parentId && (
                <>
                  <a
                    href={getSearchUrl(title, { seasonNumber: false })}
                    target={'_blank'}
                    rel="noreferrer">
                    <span className="text-grey p-0 justify-center items-center">Other Series</span>
                  </a>
                  <ArrowRightIcon className="w-4 h-4 mt-0.5 stroke-gray-400" />
                </>
              )}

            {isEpisode && title.seasonNumber && (
              <>
                <a target={'_blank'} rel="noreferrer" href={getSearchUrl(title)}>
                  <span className="p-0 justify-center items-center">S{title.seasonNumber} </span>
                </a>
                <ArrowRightIcon className="w-4 h-4 mt-0.5 stroke-gray-400" />
              </>
            )}

            <span>
              <>
                <a href={`/repository/${title._id}`} target={'_blank'} rel="noreferrer">
                  <span className="font-bold">{title.title}</span>
                  {isEpisode && title.episodeNumber && (
                    <span className="text-gray-500"> (E{title.episodeNumber})</span>
                  )}
                </a>
              </>
            </span>
          </div>
        </div>
      );
    }
    return (
      <div className="flex gap-3 items-center">
        <div>{iconsMap[title.itemType]}</div>
        <div>
          <div className="text-primary-600 font-medium">{renderContentTitle(title)}</div>
        </div>
      </div>
    );
  }
  function renderExternalIds(title: RepositoryItem, formValues?: FormValues): ReactElement {
    const isChangedCatalogId = title.titleCatalogId !== formValues?.catalogId;
    const isChangedEidrId = title.eidrId !== formValues?.eidrId;
    return (
      <div className={'flex pl-9 px-8 w-full justify-between gap-8'}>
        <div>
          <div className={'text-sm text-gray-600'}>
            {title.catalogIdType || formValues?.catalogIdType}: {title.titleCatalogId}
          </div>
          <div className={'text-sm text-gray-600 min-w-80'}>EIDR: {title.eidrId}</div>
        </div>
        <div className={'flex items-center justify-center text-gray-600'}>-&gt;</div>
        <div>
          <div
            className={clsx('text-sm text-gray-600', {
              underline: isChangedCatalogId
            })}>
            {formValues?.catalogIdType}: {formValues?.catalogId}
          </div>
          <div
            className={clsx('text-sm text-gray-600', {
              underline: isChangedEidrId
            })}>
            EIDR: {formValues?.eidrId}
          </div>
        </div>
      </div>
    );
  }

  function getContext(itemsWithSameIds: RepositoryItem[]) {
    let sameCatalog = false;
    let itemsWithSameEidr = false;

    itemsWithSameIds.forEach((item) => {
      if (item.titleCatalogId === repositoryItem?.titleCatalogId) {
        sameCatalog = true;
      }
      if (item.eidrId === repositoryItem?.eidrId) {
        itemsWithSameEidr = true;
      }
    });

    if (sameCatalog && itemsWithSameEidr) {
      return (
        '' +
        (repositoryItem?.titleCatalogId
          ? `${repositoryItem?.catalogIdType}: ${repositoryItem?.titleCatalogId}`
          : '') +
        ' OR EIDR: ' +
        (repositoryItem?.eidrId ? `${repositoryItem?.eidrId}` : '')
      );
    }
    if (sameCatalog) {
      return (
        repositoryItem?.catalogIdType +
        ' ' +
        (repositoryItem?.titleCatalogId ? `${repositoryItem?.titleCatalogId}` : '')
      );
    }
    if (itemsWithSameEidr) {
      return 'EIDR ID ' + (repositoryItem?.eidrId ? `${repositoryItem?.eidrId}` : '');
    }

    return '';
  }

  return (
    <Modal
      visible={true}
      setVisible={() => null}
      showHeader={false}
      onOverlayClick={() => clickHandler('CANCEL')}
      position={'center'}>
      <div className="p-8">
        {/* Header */}
        <div className={'flex w-full justify-between mb-5'}>
          <div>
            <h3 className="text-gray-900 text-lg font-semibold mb-1">
              Multiple Titles Found with Same IDs
            </h3>
          </div>
          <div className="flex-none cursor-pointer" onClick={() => clickHandler('CANCEL')}>
            <CloseIcon className="h-6 w-6 stroke-gray-400" />
            <span className="sr-only">Close</span>
          </div>
        </div>
        {/* Content */}
        <div>
          {conflictItems && (
            <div>
              <h4 className="text-gray-700 text-sm font-medium mb-1.5">
                <span className={'block'}>
                  We found multiple titles with the same {getContext(conflictItems)}
                </span>
                <span className={'block mt-1'}>
                  Do you want to also update the IDs for the following titles?
                </span>
              </h4>
              <div className={'flex flex-col gap-4  '}>
                {conflictItems?.map((item, index) => {
                  return (
                    <div
                      key={`catalogId-${index}`}
                      className="flex  gap-2 flex-col border rounded-xl p-4 w-full relative border-gray-200 mt-4">
                      {renderContentTitleColumn(item)}
                      {renderExternalIds(item, formValues)}
                    </div>
                  );
                })}
              </div>
            </div>
          )}
        </div>
        {/* Footer */}
        <div className="flex justify-center mt-8 gap-8">
          <Button
            rounded
            variant="primary"
            className="font-semibold text-md min-w-max"
            disabled={loading}
            onClick={() => clickHandler('UPDATE_ONE')}>
            Update One
          </Button>
          <Button
            rounded
            variant="primary"
            disabled={loading}
            className="font-semibold text-md min-w-max"
            onClick={() => clickHandler('UPDATE_ALL')}>
            Update All
          </Button>
        </div>
      </div>
    </Modal>
  );
}

export default ConfirmUpdateRepositoryItemExternalIdsModal;
